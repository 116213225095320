import { Entitlements } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import isString from 'lodash/isString';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR, { useSWRConfig } from 'swr';

import * as RouteNames from '../../../../common/constants/RouteNames';
import useCheckLicense from '../../../../common/hooks/useCheckLicense';
import { useOrganizationName } from '../../../../common/hooks/useOrganizationName';
import { EncryptionType } from '../../../../common/interfaces/encryption';
import type { ICertMetaData } from '../../../../common/interfaces/externalIdentity';
import {
    getCustomerManagedKeyStatus,
    getKmsConfig,
    kmsUrl,
} from '../../../../services/identity/KeyManagementService';
import { isEntitled } from '../../../../services/licensing/EntitlementsService';
import { accountGlobalId } from '../../../../store/selectors';
import EncryptionConsentDialogBody from './EncryptionConsentDialogBody';
import { EncryptionErrorDialogBody } from './EncryptionErrorDialogBody';
import EncryptionWarningDialogBody from './EncryptionWarningDialogBody';

const entitlementsKey = '/api/license/accountant/Entitlement/';

const useEncryptionViewModel = () => {
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const createDialog = useShowDialog();
    const { isUserEnterprise } = useCheckLicense();
    const organizationName = useOrganizationName();
    const EnableCustomerManagedKeyEntitlement = useFeatureFlagValue(Features.EnableCustomerManagedKeyEntitlement.name);
    const EnableCustomerManagedKeyErrorBanner = useFeatureFlagValue(Features.EnableCustomerManagedKeyErrorBanner.name);
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const partitionGlobalId = useSelector(accountGlobalId);

    const { mutate } = useSWRConfig();
    const {
        data: kmsConfig, isLoading: loading,
    } = useSWR(
        {
            url: kmsUrl,
            partitionGlobalId,
        },
        getKmsConfig,
    );

    const { error: cmkError } = useSWR(
        {
            url: `${kmsUrl}/cmkstatus`,
            partitionGlobalId,
        },
        EnableCustomerManagedKeyErrorBanner ? getCustomerManagedKeyStatus : null,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            onErrorRetry: (error) => {
                // Never retry on error 424
                if (error.status === 424) {
                    return;
                }
            },
        }
    );

    const [ encryptionType, setEncryptionType ] = useState<EncryptionType>(EncryptionType.UIPATH);
    const [ certMetadata, setCertMetadata ] = useState<ICertMetaData>();

    useEffect(() => {
        if (kmsConfig) {
            setEncryptionType(kmsConfig.keyType === 1 ? EncryptionType.CUSTOMER : EncryptionType.UIPATH);
            try {
                const configuration = JSON.parse(kmsConfig.configuration);
                setCertMetadata(configuration.CertMetaData);
            } catch (error) {
                portalTelemetry.trackTrace({
                    message: 'Failed to parse KMS config JSON',
                    severityLevel: SeverityLevel.Major,
                    properties: { error },
                });
            }
        }
    }, [ kmsConfig ]);

    const { data: hasEntitlement } = useSWR(
        EnableCustomerManagedKeyEntitlement ? {
            url: entitlementsKey,
            entitlement: Entitlements.CustomerManagedKey,
        } : null,
        isEntitled,
    );

    const refreshRadioGroup = useCallback(() => {
        mutate({
            url: kmsUrl,
            partitionGlobalId,
        });
    }, [ mutate, partitionGlobalId ]);

    const addConnection = useCallback(() => {
        navigate(getRoute(`${RouteNames.Encryption}/configure/add`));
    }, [ getRoute, navigate ]);

    const editConnection = useCallback(() => {
        navigate(getRoute(`${RouteNames.Encryption}/configure/edit`));
    }, [ getRoute, navigate ]);

    const handleError = useCallback(async (error: Error) => {
        const errorObject = await getErrorObject(error);
        if (errorObject.response?.status === 424) {
            await createDialog({
                title: translate({ id: 'CLIENT_ENCRYPTION_AUTHENTICATION_FAILED' }),
                customDialogContent: EncryptionErrorDialogBody,
                icon: 'error',
            });
        } else {
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
        }

    }, [ createDialog, getErrorMessage, getErrorObject, setErrorMessage, translate ]);

    const handleChange = useCallback(async (_: any, value: string) => {
        if (value === EncryptionType.CUSTOMER) {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_ENCRYPTION_CONSENT_HEADER' }),
                customDialogContent: EncryptionConsentDialogBody,
                customDialogContentProps: { organizationName },
                icon: 'warning',
            });
            if (proceed) {
                addConnection();
            }
        } else {
            await createDialog({
                title: translate({ id: 'CLIENT_ENCRYPTION_WARNING_TITLE' }),
                customDialogContent: EncryptionWarningDialogBody,
                customDialogContentProps: {
                    refreshCallback: refreshRadioGroup,
                    errorCallback: handleError,
                },
                icon: 'info',
            });
        }
    }, [ createDialog, translate, organizationName, addConnection, refreshRadioGroup, handleError ]);

    return {
        addConnection,
        editConnection,
        handleChange,
        handleError,
        encryptionType,
        kmsConfig,
        isUserEnterprise,
        hasEntitlement,
        loading,
        certMetadata,
        cmkError,
    };
};

export default useEncryptionViewModel;
