import { useUiDataContext } from '@experiences/ui-common';
import {
    DAYS,
    useQueryState,
} from '@experiences/util';
import type {
    Column,
    IFilterModel,
} from '@uipath/apollo-angular-elements';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import type { DateTimeRange } from '../../common/UiDatePicker/UiDatePicker';
import {
    getQuickFilter,
    QuickFilters,
} from '../../common/UiDatePicker/UiDatePickerUtil';
import type {
    AuditEventSourceDto,
    AuditEventStatus,
    IAuditDetailsContext,
    IFilter,
} from '../interfaces/auditLog';
import { AuditEventStatusMap } from '../interfaces/auditLog';
import {
    getDateFilterString,
    getDropdownValue,
    getSuggestValue,
} from './AuditGridUtils';

const FILTER_DEBOUNCE_TIME_MS = 500;

const DEFAULT_TIME_FILTER = {
    from: new Date(Date.now() - 90 * DAYS),
    to: new Date(),
};

export function useAuditGridFilterModel() {
    const { formatMessage: translate } = useIntl();

    const { data: { filterManager } } = useUiDataContext<IAuditDetailsContext>();

    const [ filters, setFilters ] = useQueryState<Array<IFilterModel<AuditEventSourceDto>>>('filter', [], undefined, true);

    const [ userMeta, setUserMeta ] = useQueryState<IFilter[]>('user', [], undefined, true);

    const [ timeFilter, setTimeFilter ] = useQueryState<DateTimeRange>('time', DEFAULT_TIME_FILTER, (k, value) => k ? new Date(value) : value, true);

    const [ timeFilterAnchorEl, setTimeFilterAnchorEl ] = useState<HTMLElement | null>(null);
    const [ isClearing, setIsClearing ] = useState<boolean>(false);

    const [ debouncedFilters ] = useDebounce(filters, FILTER_DEBOUNCE_TIME_MS);

    const [ userFilter, sourceFilter, categoryFilter, actionFilter, statusFilter ] = useMemo(() => {
        const user = filters.find(f => f.property === 'actorName')?.value as string[];
        const debouncedUser = debouncedFilters.find(f => f.property === 'actorName')?.value as string[];

        const eventSource = filters.find(f => f.property === 'eventSource')?.value as string[];
        const debouncedEventSource = debouncedFilters.find(f => f.property === 'eventSource')?.value as string[];

        const eventTarget = filters.find(f => f.property === 'eventTarget')?.value as string[];
        const debouncedEventTarget = debouncedFilters.find(f => f.property === 'eventTarget')?.value as string[];

        const eventType = filters.find(f => f.property === 'eventType')?.value as string[];
        const debouncedEventType = debouncedFilters.find(f => f.property === 'eventType')?.value as string[];

        const status = filters.find(f => f.property === 'status')?.value as AuditEventStatus;
        const debouncedStatus = debouncedFilters.find(f => f.property === 'status')?.value as AuditEventStatus;

        return [
            {
                value: user,
                debouncedValue: debouncedUser,
                suggestValue: user?.map(u => ({
                    id: u,
                    text: userMeta?.find(m => m.id === u)?.text ?? u,
                })),
            },
            {
                value: eventSource,
                debouncedValue: debouncedEventSource,
                suggestValue: getSuggestValue(eventSource),
            },
            {
                value: eventTarget,
                debouncedValue: debouncedEventTarget,
                suggestValue: getSuggestValue(eventTarget),
            },
            {
                value: eventType,
                debouncedValue: debouncedEventType,
                suggestValue: getSuggestValue(eventType),
            },
            {
                value: status,
                debouncedValue: debouncedStatus,
                suggestValue: getDropdownValue(status, AuditEventStatusMap[status]),
            },
        ];
    }, [ debouncedFilters, filters, userMeta ]);

    const timeFilterLabel = useMemo(() => {
        const {
            id, args,
        } = getDateFilterString(timeFilter.from, timeFilter.to);

        return ({
            title: translate({ id: 'CLIENT_DATE_CREATED_COLON' }),
            value: translate({ id }, args),
        });
    }, [ timeFilter.from, timeFilter.to, translate ]);

    const isDefaultTimeFilter = useMemo(() => getQuickFilter(timeFilter.from, timeFilter.to) === QuickFilters.Last90Days, [ timeFilter.from, timeFilter.to ]);

    const handleTimeFilterChange = useCallback((event?: React.MouseEvent<HTMLButtonElement>) => {
        setTimeFilterAnchorEl(event?.currentTarget ?? null);
    }, []);

    const clearTimeFilter = useCallback((e?: React.MouseEvent<SVGElement>) => {
        e?.stopPropagation();
        setTimeFilter(DEFAULT_TIME_FILTER);
    }, [ setTimeFilter ]);

    const clearFilters = useCallback(() => {
        setIsClearing(true);

        // @ts-ignore
        filterManager?.clear();
        clearTimeFilter();
    }, [ clearTimeFilter, filterManager ]);

    const onFilterChange = useCallback((filter: Array<IFilterModel<AuditEventSourceDto>>) => {
        const sourceFilter_ = filter.find(f => f.property === 'eventSource');
        const categoryFilter_ = filter.find(f => f.property === 'eventTarget');
        const actionFilter_ = filter.find(f => f.property === 'eventType');
        const userFilterMeta = filter.find(f => f.property === 'actorName')?.meta;

        // if the source filter is missing, then there should be no filters selected
        if (!sourceFilter_ && (categoryFilter_ || actionFilter_)) {
            clearFilters();
            return;
        }

        // if the category filter is selected but the action filter isn't, then deselect action filter
        if (!categoryFilter_ && actionFilter_) {
            const actionsColumn = filterManager?.columns.find(c => c.property === 'eventType') as Column<any>;

            filterManager?.dropdownUpdate(actionsColumn, undefined);
            return;
        }

        // save metadata on user filters, this way we can show username without making an extra network request
        if (Array.isArray(userFilterMeta)) {
            setUserMeta(userFilterMeta.map((m) => ({
                id: m.id,
                text: m.text,
            })));
        }

        const filtersWithoutMeta = filter.map(({
            meta, ...rest
        }) => rest);

        setFilters(filtersWithoutMeta);
    }, [ clearFilters, filterManager, setFilters, setUserMeta ]);

    useEffect(() => {
        if (!filterManager) {
            return;
        }

        const subscription = filterManager.filter$.asObservable().subscribe(onFilterChange);

        return () => subscription.unsubscribe();
    }, [ filterManager, onFilterChange ]);

    useEffect(() => {
        if (debouncedFilters.length === 0 && isDefaultTimeFilter) {
            setIsClearing(false);
        }
    }, [ debouncedFilters.length, isDefaultTimeFilter ]);

    return {
        filters: debouncedFilters,
        sourceFilter,
        categoryFilter,
        actionFilter,
        userFilter,
        statusFilter,
        timeFilter: {
            value: timeFilter,
            hasValue: !isDefaultTimeFilter,
            anchorEl: timeFilterAnchorEl,
            open: Boolean(timeFilterAnchorEl),
            label: timeFilterLabel,
            set: setTimeFilter,
            clear: clearTimeFilter,
            toggle: handleTimeFilterChange,
        },
        isClearing,
        setFilters,
        clearFilters,
    };
}
