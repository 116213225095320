import { OperationTypeConstants } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useSupportedLanguagesMap } from '@experiences/locales';
import {
    UiAlertBanner,
    UiCopyButton,
    UiProgressButton,
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import {
    getAsset,
    getMiddleTruncatedString,
    useNavigateWithParams,
    useRouteResolver,
    validateAccountLogicalName,
    validOrganizationLogicalName,
} from '@experiences/util';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import type { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { TextFieldClassName } from '@uipath/portal-shell-util';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import useSWR from 'swr';
import xss from 'xss';

import useUserInfo from '../../../auth/hooks/UserInfo';
import {
    accountSetting,
    notificationType,
} from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { triggerPortalShellRefresh } from '../../../common/hooks/triggerPortalShellRefresh';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { ServiceErrorCode } from '../../../common/interfaces/error';
import type { IOrganizationError } from '../../../common/interfaces/organization';
import {
    cobrandingUrl,
    deleteLogo,
    getLogos,
    uploadLogo,
} from '../../../services/organization/CobrandingService';
import { updateOrganization } from '../../../services/organization/OrganizationService';
import { setUserProfile } from '../../../store/action/UserProfileAction';
import { profile } from '../../../store/selectors';
import UiForm from '../../common/UiForm';
import { useTenantOperationTrackerContext } from '../../tenants/TenantOperationTrackerContextProvider';
import CobrandingComponent from '../subcomponents/CobrandingComponent';
import type {
    IGeneralSettingsForm,
    ILogo,
    ILogoSettingsDto,
    IUpdateOrganizationResponse,
} from './types/settings';

interface GeneralSettingsThemeProps {
    width: string | undefined;
}

const useStyles = makeStyles<Theme, GeneralSettingsThemeProps>(theme =>
    createStyles({
        root: props => ({
            paddingTop: '20px',
            width: props.width,
        }),
        input: { marginTop: '24px' },
        select: { marginTop: '16px' },
        generalSettingsTitle: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '8px',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        warningText: { fontSize: '12px !important' },
        flex: { display: 'flex' },
        supportIdContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        supportIdLabel: {
            fontWeight: 600,
            lineHeight: '15px',
            marginBottom: '2px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        supportId: { color: theme.palette.semantic.colorForegroundDeEmp },
        actions: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '28px',
            marginBottom: '24px',
        },
        rightAlignedActions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            marginTop: '16px',
        },
    }),
);

const EnablePortalLogoCobrandingAdminUX = !process.buildConfigs.disablePortalLogoCobrandingAdminUX;

const orgNameIsOrgLogicalName = process.buildConfigs.orgNameIsOrgLogicalName;

const GeneralSettingsComponent: React.FC = () => {
    const classes = useStyles({ width: '100%' });
    const { formatMessage: translate } = useIntl();

    const setErrorMessage = useCentralErrorSetter();
    const { parseErrorObject } = useGetErrorInfo();

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    const navigate = useNavigateWithParams();
    // eslint-disable-next-line ban/ban
    const originalNavigate = useNavigate();
    const location = useLocation();
    const getRoute = useRouteResolver();
    const {
        addTenantOperation, checkOperationList,
    } = useTenantOperationTrackerContext();

    const createNotification = useUiSnackBar();

    const [ loading, setLoading ] = useState(false);
    const [ operationId, setOperationId ] = useState('');
    const [ savedPayload, setSavedPayload ] = useState<IGeneralSettingsForm>();

    const languageOptions = useSupportedLanguagesMap();

    const accountProfile = useSelector(profile);

    const { token } = useUserInfo();

    const {
        companyName,
        accountLogicalName,
        accountLanguage,
    } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);

    const {
        data: logos,
        error: logoError,
        mutate: triggerLogos,
    } = useSWR<ILogoSettingsDto>(EnablePortalLogoCobrandingAdminUX ? [ cobrandingUrl ] : null, getLogos);

    const defaultValues = useMemo(() => ({
        companyName: companyName ?? '',
        logicalName: accountLogicalName ?? '',
        language: !accountLanguage || accountLanguage?.toLowerCase() === 'keys' ? 'en' : accountLanguage,
        lightLogo: {
            url: '',
            base64: '',
        },
        darkLogo: {
            url: '',
            base64: '',
        },
    }), [ accountLanguage, accountLogicalName, companyName ]);

    const methods = useForm<IGeneralSettingsForm>({
        mode: 'onSubmit',
        defaultValues,
        shouldUnregister: false,
    });

    const {
        control, handleSubmit, reset, setError, formState: {
            errors, dirtyFields,
        }, setValue, watch,
    } = methods;

    const currentCompanyName = watch('companyName');

    const initializeLogos = useCallback(async () => {
        const lightLogo = defaultValues.lightLogo;
        const darkLogo = defaultValues.darkLogo;

        if (logos?.lightLogo) {
            lightLogo.url = logos.lightLogo;
        }

        if (logos?.darkLogo) {
            darkLogo.url = logos.darkLogo;
        }

        if (lightLogo.url) {
            lightLogo.base64 = await getAsset(lightLogo.url, token, { base64: true });
        }

        if (darkLogo.url) {
            darkLogo.base64 = await getAsset(darkLogo.url, token, { base64: true });
        }

        reset({
            ...defaultValues,
            darkLogo,
            lightLogo,
        });
    }, [ defaultValues, logos, reset, token ]);

    const onReset = useCallback(() => {
        triggerLogos();
        reset();
    }, [ reset, triggerLogos ]);

    const saveLogos = useCallback(async (lightLogo: ILogo | null, darkLogo: ILogo | null) => {
        const cobrandingPromises = [];

        const lightLogoUpdated = lightLogo?.image && dirtyFields.lightLogo?.url;
        const darkLogoUpdated = darkLogo?.image && dirtyFields.darkLogo?.url;

        const lightLogoDeleted = !lightLogo?.url && dirtyFields.lightLogo?.url;
        const darkLogoDeleted = !darkLogo?.url && dirtyFields.darkLogo?.url;

        if (lightLogoUpdated) {
            cobrandingPromises.push(uploadLogo('light', lightLogo.image!));
        }

        if (lightLogoDeleted) {
            cobrandingPromises.push(deleteLogo('light'));
        }

        if (darkLogoUpdated) {
            cobrandingPromises.push(uploadLogo('dark', darkLogo.image!));
        }

        if (darkLogoDeleted) {
            cobrandingPromises.push(deleteLogo('dark'));
        }

        await Promise.all(cobrandingPromises);

        triggerPortalShellRefresh();
    }, [ dirtyFields ]);

    const onSaveProceed = useCallback(
        async (data: IGeneralSettingsForm) => {
            const {
                companyName: companyNameFormData, logicalName, language: languageFormData,
            } = data;

            // language should never be undefined
            const language = languageFormData ?? 'en';

            try {
                const logicalNameChanged = dirtyFields.logicalName;
                const companyNameChanged = dirtyFields.companyName;

                const response: IUpdateOrganizationResponse = await updateOrganization({
                    companyName: companyNameFormData,
                    logicalName,
                    language,
                });

                if (companyNameChanged) {
                    setOperationId(response.id);
                    addTenantOperation(response.operationId, response.logicalName, response.id, OperationTypeConstants.ORGANIZATION);
                    createNotification(translate({ id: 'CLIENT_ACCOUNT_SETTINGS_CHANGED_ASYNC' }), notificationType.INPROGRESS);
                } else {
                    createNotification(translate({ id: 'CLIENT_ACCOUNT_SETTINGS_CHANGED' }));
                }

                if (EnablePortalLogoCobrandingAdminUX) {
                    await saveLogos(data.lightLogo, data.darkLogo);
                }

                const updateProfile = Object.assign({}, accountProfile);
                updateProfile.accountUserDto.companyName = companyNameFormData;
                updateProfile.accountUserDto.accountLogicalName = logicalName;
                updateProfile.accountUserDto.accountLanguage = language;

                setUserProfile(updateProfile);

                // set param in URL to avoid rendering Organization Switching warning dialog
                if (logicalNameChanged) {
                    originalNavigate({
                        pathname: `/${data.logicalName}/portal_/settings`,
                        search: '?isOrgNameUpdate=true',
                    });
                    return;
                }

                reset(data);
            } catch (error) {
                const parsedError: IOrganizationError = await parseErrorObject(error);
                if (parsedError.ErrorCode === ServiceErrorCode.Conflict) {
                    setError('logicalName', { type: 'duplicate' });
                    setErrorMessage(translate({ id: 'CLIENT_BLACKLISTED_ACCOUNT_LOGICAL_NAME' }));
                } else if (parsedError.ErrorCode === ServiceErrorCode.ClientError && parsedError.Message.includes('is in-progress')) {
                    createNotification(translate({ id: 'CLIENT_OPERATION_IN_PROGRESS' }), notificationType.INPROGRESS);
                } else {
                    setErrorMessage(translate({ id: 'CLIENT_GENERIC_ERROR_MESSAGE' }));
                }
            } finally {
                setLoading(false);
            }
        },
        [
            dirtyFields.logicalName,
            dirtyFields.companyName,
            accountProfile,
            reset,
            addTenantOperation,
            createNotification,
            translate,
            saveLogos,
            navigate,
            parseErrorObject,
            setError,
            setErrorMessage,
        ],
    );

    const onSave = useCallback(
        async (data: IGeneralSettingsForm) => {
            setLoading(true);

            data.logicalName = xss(data.logicalName.trim());
            data.companyName = xss(data.companyName.trim());

            if (process.buildConfigs.orgNameIsOrgLogicalName && !validateAccountLogicalName(data.companyName)) {
                setError('companyName', { type: 'invalidLogical' });
                setLoading(false);
                return;
            }

            if (!validateAccountLogicalName(data.logicalName)) {
                setError('logicalName', { type: 'invalid' });
                setLoading(false);
                return;
            }

            if (accountLogicalName.toLowerCase() !== data.logicalName.toLowerCase()) {
                setSavedPayload(data);
                navigate(getRoute(RouteNames.OrganizationSettingsWarning));
            } else {
                await onSaveProceed(data);
            }
        },
        [ accountLogicalName, getRoute, navigate, onSaveProceed, setError ],
    );

    useEffect(() => {
        if (logos) {
            initializeLogos();
        }
    }, [ initializeLogos, logos ]);

    useEffect(() => {
        if (orgNameIsOrgLogicalName) {
            setValue('logicalName', currentCompanyName.toLowerCase(), { shouldDirty: true });
        }
    }, [ setValue, currentCompanyName ]);

    useEffect(() => {
        if (location.state && (location.state as any)['proceed'] && savedPayload) {
            onSaveProceed(savedPayload);
        } else {
            setLoading(false);
        }
    }, [ loading, savedPayload, onSaveProceed, location ]);

    const formActions = useMemo(() => (
        <div className={classes.rightAlignedActions}>
            <Button
                className={classes.cancelButton}
                onClick={onReset}
                color="primary"
                data-cy="organization-settings-form-reset-button"
            >
                {translate({ id: 'CLIENT_RESET' })}
            </Button>
            <UiProgressButton
                loading={loading}
                disabled={!!logoError || checkOperationList(operationId)}
                type="submit"
                variant="contained"
                data-cy="organization-settings-form-submit-button"
            >
                {translate({ id: 'CLIENT_SAVE_CHANGES' })}
            </UiProgressButton>
        </div>
    ), [ checkOperationList, classes, loading, logoError, onReset, operationId, translate ]);

    return <UiForm
        className={classes.root}
        onSubmit={handleSubmit(onSave)}
        actions={Object.keys(dirtyFields).length ? formActions : undefined}
        centerChild
        heightOffset="0px">
        <>
            {checkOperationList(operationId) && <UiAlertBanner
                dataCy='organization-info-updating-banner'
                type="warning"
                closeable={false}
                enterpriseTrialAlertBar>
                <div>
                    {translate({ id: 'CLIENT_ORGANIZATION_STATUS_UPDATING' })}
                </div>
            </UiAlertBanner>}
            <Controller
                control={control}
                name="companyName"
                rules={{
                    required: true,
                    validate: (value: string) => {
                        if (value.length > accountSetting.companyNameLength) {
                            return 'maxLength';
                        }
                        if (!validOrganizationLogicalName(value.trim())) {
                            return 'invalidLogical';
                        }
                        return true;
                    },
                }}
                render={({ field }) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        label={`${translate({ id: 'CLIENT_ORGANIZATION_NAME' })} *`}
                        InputLabelProps={{ id: 'organizationNameLabel' }}
                        disabled={checkOperationList(operationId)}
                        error={!!errors.companyName}
                        helperText={
                            (errors.companyName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                            || ([ errors.companyName?.type, errors.companyName?.message ].includes('invalidLogical')
                            && translate(
                                { id: 'CLIENT_INVALID_ACCOUNT_LOGICAL_NAME_ORG' },
                                { 0: accountSetting.companyNameLength },
                            ))
                            || (errors.companyName
                            && translate({ id: 'CLIENT_VALID_COMPANY_NAME' }, { 0: accountSetting.companyNameLength }))
                        }
                        InputProps={{ className: 'Tall' }}
                        fullWidth
                        data-cy="organization-settings-form-companyName"
                        inputProps={{ 'aria-labelledby': 'organizationNameLabel' }}
                    />
                )}
            />
            <div className={classes.input}>
                <Controller
                    control={control}
                    name="logicalName"
                    rules={{
                        required: true,
                        maxLength: accountSetting.accountLogicalNameLength,
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={translate({ id: 'CLIENT_URL' })}
                            required
                            variant="outlined"
                            error={!!errors.logicalName}
                            helperText={
                                (errors.logicalName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                    || (errors.logicalName?.type === 'duplicate' && translate({ id: 'CLIENT_BLACKLISTED_ACCOUNT_LOGICAL_NAME' }))
                                    || (errors.logicalName && translate(
                                        { id: 'CLIENT_INVALID_ACCOUNT_LOGICAL_NAME' },
                                        { 0: accountSetting.accountLogicalNameLength },
                                    ))
                                    || translate({
                                        id: process.buildConfigs.disableUserInvite
                                            ? 'CLIENT_URL_CHANGE_WARNING_HELPER_TEXT_NO_INVITE'
                                            : 'CLIENT_URL_CHANGE_WARNING_HELPER_TEXT',
                                    })
                            }
                            FormHelperTextProps={{ className: classes.warningText }}
                            InputProps={{
                                readOnly: orgNameIsOrgLogicalName,
                                className: `Tall ${orgNameIsOrgLogicalName ? 'Mui-disabled' : ''}`,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <UiText>
                                            {getMiddleTruncatedString(`${window.location.origin}/`)}
                                        </UiText>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <UiCopyButton
                                            textToCopy={`${window.location.origin}/${watch('logicalName')}`}
                                            aria-label={translate({ id: 'CLIENT_COPY_LOGICAL_NAME' })}
                                            data-cy="organization-settings-form-logicalName-copy-button"
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{ className: `${orgNameIsOrgLogicalName ? 'Mui-disabled' : ''}` }}
                            className={TextFieldClassName.OmitStartAdornmentSpacing}
                            fullWidth
                            data-cy="organization-settings-form-logicalName"
                        />
                    )}
                />
            </div>
            {!DisableFeatureFedRamp && (
                <div className={classes.select}>
                    <UiSelect
                        control={control}
                        dataCy="organization-settings-form-language"
                        inputLabel={translate({ id: 'CLIENT_LANGUAGE' })}
                        name="language"
                        options={languageOptions}
                        error={!!errors.language}
                        fullWidth
                        isTranslated
                        required
                        helperText={translate({ id: 'CLIENT_LANGUAGE_CHANGE_NOTE_HELPER_TEXT' })}
                    />
                </div>
            )}
            {EnablePortalLogoCobrandingAdminUX && (
                <div className={classes.input}>
                    <FormProvider {...methods}>
                        <CobrandingComponent />
                    </FormProvider>
                </div>
            )}
        </>
    </UiForm>;
};

export default GeneralSettingsComponent;
