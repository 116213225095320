import {
    hasDigit,
    hasLowercase,
    hasSpecialCharacter,
    hasUppercase,
} from '@experiences/util';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React, {
    useEffect
    , useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { AuthSettingPasswordKey } from '../../common/constants/AuthSettingConstant';
import type { IEditPasswordData } from '../../common/interfaces/cis/account';
import {
    getSetting,
    settingUrl,
} from '../../services/identity/SettingService';
import { accountGlobalId } from '../../store/selectors';
import { mapSettingArrayToSecurityAuthSettingsData } from '../../util/setting/AuthSettingUtil';

export const defaultEditPasswordData: IEditPasswordData = {
    password: '',
    passwordRepeat: '',
};

export const useEditPasswordStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
    }),
);

const EditPasswordFormComponent: FC<{
    passwordLabel?: string;
    required?: boolean;
    respectPasswordRequirements?: boolean;
}> = ({
    passwordLabel, required = false, respectPasswordRequirements = false,
}) => {
    const classes = useEditPasswordStyles();
    const {
        formatMessage: translate, formatList,
    } = useIntl();

    const {
        control,
        watch,
        trigger,
        formState: { errors },
    } = useFormContext<IEditPasswordData>();

    const [ watchPassword, watchPasswordRepeat ] = watch([ 'password', 'passwordRepeat' ]);

    const partitionGlobalId = useSelector(accountGlobalId);

    const keys = useMemo(() => [ AuthSettingPasswordKey.PasswordComplexity ], []);
    const { data: settingArray } = useSWR(
        {
            url: settingUrl,
            key: keys,
            partitionGlobalId,
        },
        respectPasswordRequirements ? getSetting : null,
        { shouldRetryOnError: false },
    );
    const passwordComplexity = useMemo(
        () => settingArray && mapSettingArrayToSecurityAuthSettingsData(settingArray)?.password?.passwordComplexity,
        [ settingArray ],
    );

    const defaultPasswordHelperText = useMemo(() => {
        const length = passwordComplexity?.Length;
        const requirements = formatList(
            [
                ...(passwordComplexity?.hasDigit ? [ translate({ id: 'CLIENT_DIGIT_REQUIREMENT' }) ] : []),
                ...(passwordComplexity?.hasLowercase ? [ translate({ id: 'CLIENT_LOWERCASE_CHARACTER_REQUIREMENT' }) ] : []),
                ...(passwordComplexity?.hasSpecialCharacter ? [ translate({ id: 'CLIENT_SPECIAL_CHARACTER_REQUIREMENT' }) ] : []),
                ...(passwordComplexity?.hasUppercase ? [ translate({ id: 'CLIENT_UPPERCASE_CHARACTER_REQUIREMENT' }) ] : []),
                ...(length ? [ translate({ id: 'CLIENT_MIN_CHARACTER_LENGTH_REQUIREMENT' }, { length }) ] : []),
            ],
            {
                style: 'long',
                type: 'conjunction',
            },
        );
        return requirements.length ? translate({ id: 'CLIENT_PASSWORD_REQUIREMENTS' }, { requirements }) : '';
    }, [
        formatList,
        passwordComplexity?.Length,
        passwordComplexity?.hasDigit,
        passwordComplexity?.hasLowercase,
        passwordComplexity?.hasSpecialCharacter,
        passwordComplexity?.hasUppercase,
        translate,
    ]);

    useEffect(() => {
        trigger([ 'password', 'passwordRepeat' ]);
    }, [ required, trigger, watchPassword, watchPasswordRepeat ]);

    return (
        <>
            <div className={classes.input}>
                <Controller
                    control={control}
                    name="password"
                    rules={{
                        required,
                        minLength: passwordComplexity?.Length,
                        validate: {
                            hasDigit: value => !value || !passwordComplexity?.hasDigit || hasDigit(value),
                            hasLowercase: value => !value || !passwordComplexity?.hasLowercase || hasLowercase(value),
                            hasSpecialCharacter: value =>
                                !value || !passwordComplexity?.hasSpecialCharacter || hasSpecialCharacter(value),
                            hasUppercase: value => !value || !passwordComplexity?.hasUppercase || hasUppercase(value),
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={passwordLabel ?? translate({ id: 'CLIENT_PASSWORD' })}
                            variant="outlined"
                            type="password"
                            autoComplete="new-password"
                            required={required}
                            helperText={
                                errors.password
                                    ? (errors.password?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                                      (errors.password?.type === 'hasDigit' && translate({ id: 'CLIENT_PASSWORD_HAS_DIGIT_ERROR' })) ||
                                      (errors.password?.type === 'hasLowercase' &&
                                          translate({ id: 'CLIENT_PASSWORD_HAS_LOWERCASE_ERROR' })) ||
                                      (errors.password?.type === 'hasSpecialCharacter' &&
                                          translate({ id: 'CLIENT_PASSWORD_HAS_SPECIAL_CHARACTER_ERROR' })) ||
                                      (errors.password?.type === 'hasUppercase' &&
                                          translate({ id: 'CLIENT_PASSWORD_HAS_UPPERCASE_ERROR' })) ||
                                      (errors.password?.type === 'minLength' &&
                                          translate({ id: 'CLIENT_PASSWORD_MIN_LENGTH_ERROR' }, { 0: passwordComplexity?.Length }))
                                    : defaultPasswordHelperText
                            }
                            error={!!errors.password}
                            fullWidth
                            InputProps={{ className: 'Tall' }}
                            InputLabelProps={{ id: 'passwordLabel' }}
                            inputProps={{ 'aria-labelledby': 'passwordLabel' }}
                            data-cy="add-edit-password"
                        />
                    )}
                />
            </div>
            <div className={classes.input}>
                <Controller
                    control={control}
                    name="passwordRepeat"
                    rules={{
                        required,
                        validate: { match: value => value === watch('password') },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={translate({ id: 'CLIENT_CONFIRM_PASSWORD' })}
                            variant="outlined"
                            type="password"
                            autoComplete="new-password"
                            required={required}
                            error={!!errors.passwordRepeat}
                            helperText={
                                errors.passwordRepeat
                                    ? (errors.passwordRepeat?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                                      (errors.passwordRepeat?.type === 'match' && translate({ id: 'CLIENT_PASSWORD_MATCH_ERROR' }))
                                    : translate({ id: 'CLIENT_PASSWORD_MATCH_ERROR' })
                            }
                            fullWidth
                            InputProps={{ className: 'Tall' }}
                            InputLabelProps={{ id: 'confirmPasswordLabel' }}
                            inputProps={{ 'aria-labelledby': 'confirmPasswordLabel' }}
                            data-cy="add-edit-password-repeat"
                        />
                    )}
                />
            </div>
        </>
    );
};

export default EditPasswordFormComponent;
