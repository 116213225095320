import type { LanguageCulture } from '@experiences/locales';
import {
    isStringEmpty,
    validateEmail,
} from '@experiences/util';

import type { IGroup } from '../common/interfaces/cis/group';
import type { BulkInviteUserData } from '../component/users/interfaces/user';
import { inviteUsers } from '../services/identity/UserService';
import reduceGroupCIS, { mapBulkInviteUserDto } from './UserGroupUtil';

const parseLine = (line: string, groups: IGroup[]) => {
    const errors = [];

    const firstComma = line.indexOf(',');
    const email = line.substring(0, firstComma).trim();
    if (!email || isStringEmpty(email) || !validateEmail(email)) {
        errors.push('CLIENT_INVALID_EMAIL');
    }

    const groupNames: string[] = (line as any)
        .toLowerCase()
        .substring(firstComma + 1)
    // use regex with global modifier instead of .replaceAll because
    // cypress doesn't recognize .replaceAll as a function
        .replace(/"/g, '')
        .split(',')
        .map((name: string) => name.trim())
        .filter((name: string) => !!name && name !== 'Everyone');

    const groupIds = groupNames.reduce((prev, name) => {
        const id = groups.find(group => group.name.toLowerCase() === name)?.id;
        if (id) {
            prev[id] = true;
        } else {
            errors.push('CLIENT_INVALID_GROUPS');
        }
        return prev;
    }, reduceGroupCIS(groups));

    return {
        email,
        groupIds,
        groupNames,
        errors,
    };
};

export const parseFile = async (file: File, groups: IGroup[]) => new Promise<BulkInviteUserData[] | undefined>(resolve => {
    const reader = new FileReader();

    reader.onload = event => {
        const content = event.target?.result as string;
        const allLines = content?.split(/\r\n|\n/).filter(line => !!line.trim());

        const users: BulkInviteUserData[] = [];
        allLines.forEach((line: string, index: number) => {
            if (index !== 0 || !line.toLowerCase().includes('email') || !line.toLowerCase().includes('group membership')) {
                users.push(parseLine(line, groups));
            }
        });
        resolve(users);
    };

    reader.onerror = () => {
        reader.abort();
        resolve(undefined);
    };

    reader.readAsText(file);
});

const processChunk = async (
    chunk: BulkInviteUserData[],
    setCompleted: React.Dispatch<React.SetStateAction<number>>,
    organizationId: string,
    orgName: string,
    language: LanguageCulture,
    provider?: string,
) => {
    try {
        const data = mapBulkInviteUserDto(chunk, language);
        const response = await inviteUsers(data, provider, language, orgName);
        return response.users.map(user => {
            const inputData = chunk.find(item => item.email === user.email) as BulkInviteUserData;
            setCompleted(c => c + 1);
            return {
                ...inputData,
                errors: user.success ? [] : [ user.errorMsg ?? 'CLIENT_EXISTING_OR_SYSTEM_ERROR' ],
            };
        });
    } catch (error) {
        chunk.forEach(data => data.errors.push('CLIENT_EXISTING_OR_SYSTEM_ERROR'));
        setCompleted(c => c + chunk.length);
        return chunk;
    }
};

export const processParsedData = async (
    parsedData: BulkInviteUserData[],
    setCompleted: React.Dispatch<React.SetStateAction<number>>,
    organizationId: string,
    language: LanguageCulture,
    orgName: string,
    provider?: string,
    chunkSize = 10,
) => {
    const processChunkPromises = [];
    const validData = parsedData.filter(data => data.errors.length === 0);
    for (let i = 0; i < validData.length; i += chunkSize) {
        const chunk = validData.slice(i, i + chunkSize);
        processChunkPromises.push(processChunk(chunk, setCompleted, organizationId, orgName, language, provider));
    }

    const [ results ] = await Promise.all(processChunkPromises);
    const failedUsers = results.filter(user => user.errors.length > 0);
    return failedUsers;
};
