import { useEcommerceTelemetry } from '@experiences/ecommerce';
import { useApolloTheme } from '@experiences/theme';
import {
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import type { PropsWithChildren } from 'react';
import React, {
    useEffect,
    useMemo,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    Licensing,
    OrganizationAdminHome,
} from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import type { ILicenseAndKey } from '../../common/interfaces/licenses';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../services/licensing/management/AccountService';
import {
    countrySelector,
    isAdminSelector,
    isUnlicensedSelector,
    profile,
} from '../../store/selectors';
import { UiHostDeprecationBanner } from '../common/UiHostDeprecationBanner/UiHostDeprecationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import EcommerceLicenseInfo from '../ecommerce/subcomponents/EcommerceLicenseInfo';
import { EcommerceLicensePaymentPendingBanner } from '../ecommerce/subcomponents/EcommerceLicensePaymentPendingBanner';
import { EcommerceLicensePendingChangeBanner } from '../ecommerce/subcomponents/EcommerceLicensePendingChangeBanner';
import { EcommercePriceChangesBanner } from '../ecommerce/subcomponents/EcommercePriceChangesBanner';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import LicenseHeaderActionComponent from './LicenseHeaderActionComponent.default';
import { UnlicensedComponent } from './Unlicensed';
import { UserLicensingTabs } from './UserLicensingTabs';
import { useShouldShowManageButton } from './useShouldShowManageButton';

const useStyles = makeStyles(theme =>
    createStyles({
        expiry: {
            marginTop: '8px',
            display: 'flex',
            minHeight: '28px',
            alignItems: 'center',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '18px',
        },
        chip: {
            background: `${theme.palette.semantic.colorBackgroundHigh} !important`,
            height: '24px',
            marginLeft: '4px',
        },
        chipLabel: { color: theme.palette.semantic.colorForegroundHigh },
    }),
);

const LicensesComponent = () => {
    const classes = useStyles();
    const { themeId } = useApolloTheme();
    const { formatMessage: translate } = useIntl();
    const logEcommerceEvent = useEcommerceTelemetry();

    const isAdmin = useSelector(isAdminSelector);
    const accountCountry = useSelector(countrySelector);
    const profileState = useSelector(profile);

    const {
        data: licenseAndKey, mutate,
    } = useSWR<ILicenseAndKey, Error>(
        licenseManagementAccountUrl,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );

    const [ license ] = useMemo(() => [ licenseAndKey?.accountLicense ], [ licenseAndKey ]);

    const ExpiryDate = useMemo(() => (license?.endDate ? new Date(license.endDate * 1000) : undefined), [ license ]);

    const shouldShowManageButton = useShouldShowManageButton(
        profileState.accountUserDto.accountLogicalName,
        license?.accountType,
    );

    const [ LicenseStatusOrPlan, ExpiryMessage ] = useMemo(() => {
        switch (license?.licenseStatus) {
            case 'EXPIRED':
                return [ 'LICENSE_EXPIRED', 'LICENSE_EXPIRED_DATE' ];
            default:
                return [ license?.accountType, 'SUBSCRIPTION_EXPIRY' ];
        }
    }, [ license ]);

    useEffect(() => {
        if (license?.accountType && license.accountType !== profileState.accountUserDto.accountType) {
            mutate();
        }
    }, [ license?.accountType, mutate, profileState ]);

    useEffect(() => {
        logEcommerceEvent('Licenses.Navigate', {
            IsAdmin: isAdmin,
            CloudOrganizationCountry: accountCountry,
        });
    }, [ logEcommerceEvent, isAdmin, accountCountry ]);

    const licenseChip = useMemo(() => <>
        {LicenseStatusOrPlan &&
            <Chip
                style={{ marginRight: '8px' }}
                label={
                    <UiText
                        data-cy="licenses-account-plan"
                        className={classes.chipLabel}
                        style={{
                            color: themeId === 'dark-hc'
                                ? Tokens.Colors.ColorOrange100
                                : themeId === 'dark'
                                    ? Tokens.Colors.ColorOrange300
                                    : Tokens.Colors.ColorOrange700,
                        }}>
                        {translate({ id: `CLIENT_${LicenseStatusOrPlan?.toUpperCase()}_PLAN` })}
                    </UiText>
                }
                className={classes.chip}
            />}
    </>, [ LicenseStatusOrPlan, classes.chip, classes.chipLabel, themeId, translate ]);

    const ecommerceAndExpiryComponent = useMemo(() => <>
        {shouldShowManageButton === undefined ? (
            <CircularProgress
                size={20}
                aria-label="circular-progress" />
        ) : (
            (shouldShowManageButton) ? (<>
                {license && (<EcommerceLicenseInfo />)}
            </>) : (
                <div className={classes.expiry}>
                    {licenseChip}
                    {ExpiryDate &&
                        <UiText>
                            {translate({ id: `CLIENT_${ExpiryMessage}` })}
                        </UiText>}
                    <div style={{ marginLeft: '8px' }}>
                        {ExpiryDate && (
                            <UiText data-cy="licenses-expiry-date">
                                <FormattedDate
                                    value={Date.UTC(ExpiryDate.getUTCFullYear(), ExpiryDate.getUTCMonth(), ExpiryDate.getUTCDate())}
                                    year="numeric"
                                    month="short"
                                    day="numeric"
                                    timeZone="UTC"
                                />
                            </UiText>
                        )}
                    </div>
                </div>)
        )}
        <UserLicensingTabs />
    </>, [
        shouldShowManageButton,
        license,
        classes.expiry,
        licenseChip,
        translate,
        ExpiryMessage,
        ExpiryDate,
    ]);

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            banner={
                <>
                    <UiHostDeprecationBanner />
                    <EcommerceLicensePendingChangeBanner />
                    <EcommercePriceChangesBanner />
                    <EcommerceLicensePaymentPendingBanner />
                </>
            }
            position='left'
            primaryActions={isAdmin && <LicenseHeaderActionComponent />}>
            {ecommerceAndExpiryComponent}
        </UiPageContainer>
    );
};

const Licenses: React.FC<PropsWithChildren> = ({ children }) => {
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    return (
        <>
            {isUnlicensedMode ? <UnlicensedComponent /> : <LicensesComponent />}
            {children}
        </>
    );
};

export const LicensesWithParams: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: OrganizationAdminHome,
            name: organizationName,
        },
        {
            index: 1,
            link: Licensing,
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ], [ organizationName, translate ]);

    return (
        <BreadcrumbProvider
            breadcrumbs={breadCrumbLinks}
            legacy>
            <Licenses>
                {children}
            </Licenses>
            <UiSuspensefulOutlet />
        </BreadcrumbProvider>
    );
};

export default Licenses;
