import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import { ApTooltip } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) =>
    createStyles({
        colorLabelContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        colorSelect: {
            height: '40px',
            width: '30%',
            minWidth: '75px',
        },
        colorList: {
            display: 'grid',
            'grid-template-columns': 'repeat(6, 1fr)',
            padding: '8px 2px',
            justifyItems: 'center',
            alignItems: 'center',
            rowGap: '12px',
        },
        colorIcon: {
            width: '40px',
            height: '40px',
            minHeight: '40px',
            borderRadius: '2px',
            boxSizing: 'border-box',
            '&:hover': { boxShadow: '0 0 2px currentColor' },
        },
        colorSelected: {
            boxShadow: 'none !important',
            border: `2px solid ${theme.palette.semantic.colorForegroundEmp}`,
            borderRadius: '3px',
        },
        colorSelectedIcon: {
            width: '22px',
            height: '22px',
            borderRadius: '2px',
        },
        emptyColor: {
            // eslint-disable-next-line max-len
            background: `linear-gradient(to bottom right, ${theme.palette.semantic.colorBackgroundRaised}, ${theme.palette.semantic.colorBackgroundRaised} 48%, ${theme.palette.semantic.colorSelection} 48%, ${theme.palette.semantic.colorSelection} 52%, ${theme.palette.semantic.colorBackgroundRaised} 52%, ${theme.palette.semantic.colorBackgroundRaised})`,
            border: `2px solid ${theme.palette.semantic.colorBorderDisabled}`,
        },
        emptyColorTooltip: {
            position: 'absolute',
            padding: '15px 20px',
            left: -1,
        },
        emptyColorSelectedIcon: {
            width: '20px',
            height: '20px',
            borderRadius: '2px',
        },
        visibleFocus: {
            border: `3px solid ${theme.palette.semantic.colorForegroundEmp}`,
            borderRadius: '4px',
        },
        selectWithLabel: { '& .MuiOutlinedInput-notchedOutline': { top: '-5px' } },
    }),
);

const defaultTenantColors = [
    [ Tokens.Colors.ColorPink300, 'CLIENT_COLOR_ARIA_PINK300' ],
    [ Tokens.Colors.ColorOrange300, 'CLIENT_COLOR_ARIA_ORANGE300' ],
    [ Tokens.Colors.ColorBlue300, 'CLIENT_COLOR_ARIA_BLUE300' ],
    [ Tokens.Colors.ColorGreen300, 'CLIENT_COLOR_ARIA_GREEN300' ],
    [ Tokens.Colors.ColorPurple300, 'CLIENT_COLOR_ARIA_PURPLE300' ],
    [ Tokens.Colors.ColorYellow300, 'CLIENT_COLOR_ARIA_YELLOW300' ],
    [ Tokens.Colors.ColorLightBlue300, 'CLIENT_COLOR_ARIA_LIGHTBLUE300' ],
    [ Tokens.Colors.ColorPink600, 'CLIENT_COLOR_ARIA_PINK600' ],
    [ Tokens.Colors.ColorOrange500, 'CLIENT_COLOR_ARIA_ORANGE500' ],
    [ Tokens.Colors.ColorBlue600, 'CLIENT_COLOR_ARIA_BLUE600' ],
    [ Tokens.Colors.ColorGreen500, 'CLIENT_COLOR_ARIA_GREEN500' ],
    [ Tokens.Colors.ColorPurple500, 'CLIENT_COLOR_ARIA_PURPLE500' ],
    [ Tokens.Colors.ColorYellow600, 'CLIENT_COLOR_ARIA_YELLOW600' ],
    [ Tokens.Colors.ColorLightBlue700, 'CLIENT_COLOR_ARIA_LIGHTBLUE700' ],
];

const UiColorPicker: React.FC<{ value: string; onChange: (...args: any[]) => void; disabled?: boolean }> = ({
    value, onChange, disabled = false,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <FormControl>
            <div className={classes.colorLabelContainer}>
                <InputLabel
                    id="tenant-color-label"
                    disabled={disabled}>
                    {translate({ id: 'CLIENT_COLOR' })}
                </InputLabel>
                <ApTooltip
                    id="tenant-color-select"
                    content={translate({ id: 'CLIENT_TENANT_SETTINGS_COLOR_TOOLTIP' })}
                    hide={disabled}
                >
                    <InfoOutlinedIcon
                        tabIndex={disabled ? -1 : 0}
                        color={disabled ? 'disabled' : 'primary'}
                        style={{
                            marginLeft: '2px',
                            width: '14px',
                        }}
                        sx={[ disabled && { outline: 'none' } ]}
                    />
                </ApTooltip>
            </div>
            <Select
                id="tenant-color-select"
                value={value}
                onChange={onChange}
                variant="outlined"
                disabled={disabled}
                labelId="tenant-color-label"
                label={translate({ id: 'CLIENT_COLOR' })}
                IconComponent={KeyboardArrowDownIcon}
                className={clsx(classes.colorSelect, classes.selectWithLabel)}
                displayEmpty
                renderValue={(color: any) =>
                    color ? (
                        <div
                            className={classes.colorSelectedIcon}
                            style={{ backgroundColor: color }} />
                    ) : (
                        <div className={clsx(classes.emptyColorSelectedIcon, classes.emptyColor)} />
                    )}
                MenuProps={{
                    PaperProps: { style: { width: '360px' } },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    classes: { list: classes.colorList },
                }}
                role="combobox"
                SelectDisplayProps={{ 'aria-label': 'tenantColorLabel' }}
                aria-expanded="false"
                data-cy="create-edit-tenant-color"
            >
                <MenuItem
                    key="empty-default-color"
                    value=""
                    className={clsx(classes.colorIcon, classes.emptyColor)}
                    classes={{ selected: classes.colorSelected }}
                    role="menuitem"
                    aria-label={translate({ id: 'CLIENT_NO_COLOR' })}
                    focusVisibleClassName={classes.visibleFocus}
                >
                    <ApTooltip content={translate({ id: 'CLIENT_NO_COLOR' })}>
                        <div className={classes.emptyColorTooltip} />
                    </ApTooltip>
                </MenuItem>

                {defaultTenantColors.map(([ color, ariaLabel ], i) => (
                    <MenuItem
                        key={i}
                        value={color}
                        style={{
                            backgroundColor: color,
                            color,
                        }}
                        className={classes.colorIcon}
                        classes={{ selected: classes.colorSelected }}
                        aria-label={translate({ id: ariaLabel })}
                        focusVisibleClassName={classes.visibleFocus}
                    />
                ))}
            </Select>
        </FormControl>
    );
};

export default UiColorPicker;
