import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { SecuritySettingsEvent } from '@experiences/telemetry';
import {
    UiAlertBanner,
    UiText,
} from '@experiences/ui-common';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ApAlertBar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    CustomerManagedKeyLink,
    EncryptionLink,
} from '../../../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import { EncryptionType } from '../../../../common/interfaces/encryption';
import lockEncryption from '../../../../images/lockEncryption.svg';
import { UiUpgradeChip } from '../../../common/UiUpgradeChip';
import useEncryptionViewModel from './EncryptionViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        centerLoader: { margin: 'auto' },
        chipSpacer: { marginLeft: '4px' },
        cmkLabel: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        default: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '4px',
        },
        description: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '28px',
        },
        editButton: { marginLeft: '28px' },
        labelDescription: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            marginBottom: '4px',
        },
        labelTitle: {
            color: theme.palette.semantic.colorForegroundEmp,
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '4px',
        },
        labelTitleContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        link: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            marginBottom: '4px',
        },
        mainContent: { width: '100%' },
        radio: { marginBottom: '18px' },
        title: {
            color: theme.palette.semantic.colorForegroundEmp,
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
        },
        certMetdataBox: {
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            padding: '16px',
            marginTop: '24px',
        },
        metdataDataDetails: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForegroundDeEmp,
            border: 'none',
            paddingTop: '0px',
            paddingRight: '24px',
            paddingLeft: '0px',
            paddingBottom: '12px',
        },
    }),
);

const EncryptionComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const EnableCustomerManagedKeyEntitlement = useFeatureFlagValue(Features.EnableCustomerManagedKeyEntitlement.name);
    const EnableKMSClientCertificate = useFeatureFlagValue(Features.EnableKMSClientCertificate.name);
    const EnableCustomerManagedKeyErrorBanner = useFeatureFlagValue(Features.EnableCustomerManagedKeyErrorBanner.name);

    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'zh-TW', 'pt', 'tr', 'ru' ] });
    const {
        editConnection,
        handleChange,
        encryptionType,
        isUserEnterprise,
        hasEntitlement,
        loading,
        certMetadata,
        cmkError,
    } = useEncryptionViewModel();

    const hasCmkError = !!cmkError && cmkError?.response?.status === 424;

    const options = useMemo(() => [
        {
            title: (
                <div className={clsx(classes.labelTitleContainer, classes.labelTitle)}>
                    <UiText>
                        {translate({ id: 'CLIENT_ENCRYPTION_UIPATH_MANAGED_KEY' })}
                    </UiText>
                    <UiText className={classes.default}>
                            (
                        {translate({ id: 'CLIENT_DEFAULT' })}
)
                    </UiText>
                </div>
            ),
            description: (
                <UiText className={classes.labelDescription}>
                    {translate({ id: 'CLIENT_ENCRYPTION_UIPATH_MANAGED_KEY_DESCRIPTION' })}
                </UiText>),
            value: EncryptionType.UIPATH,
            dataCy: 'uipath-managed-key',
            disabled: false,
        },
        {
            title: (
                <div className={clsx(classes.cmkLabel, classes.labelTitle)}>
                    <UiText>
                        {translate({ id: 'CLIENT_ENCRYPTION_CUSTOMER_MANAGED_KEY' })}
                    </UiText>
                    {(EnableCustomerManagedKeyEntitlement ? !hasEntitlement : !isUserEnterprise) && <UiUpgradeChip
                        className={classes.chipSpacer}
                        licenseType={AccountLicense.ENTERPRISE}
                        customLabel={EnableCustomerManagedKeyEntitlement ? translate({ id: 'CLIENT_ENTERPRISE_ADVANCE_ONLY' }) : undefined}
                        title={translate({ id: 'CLIENT_ENCRYPTION_UPGRADE_HEADER' })}
                        description={translate({ id: 'CLIENT_ENCRYPTION_UPGRADE_DESCRIPTION' })}
                        iconDescription={translate({ id: 'CLIENT_ENCRYPTION_UPGRADE_ICON_DESCRIPTION' })}
                        icon={
                            <img
                                src={lockEncryption}
                                alt='' />
                        }
                        telemetryTitle={SecuritySettingsEvent.EncryptionChip}
                    />}
                </div>
            ),
            description: (
                <>
                    <UiText className={classes.labelDescription}>
                        {translate({ id: 'CLIENT_ENCRYPTION_CUSTOMER_MANAGED_KEY_DESCRIPTION' })}
                    </UiText>
                    <Link
                        className={classes.link}
                        href={getLocalizedLink({ articleSlug: EncryptionLink })}
                        target='_blank'
                        underline='none'
                        data-cy='encryption-learn-more-link'
                    >
                        {translate({ id: 'CLIENT_LEARN_MORE' })}
                    </Link>
                </>),
            value: EncryptionType.CUSTOMER,
            dataCy: 'customer-managed-key',
            disabled: (EnableCustomerManagedKeyEntitlement ? !hasEntitlement : !isUserEnterprise),
        },
    ], [
        EnableCustomerManagedKeyEntitlement,
        classes.chipSpacer,
        classes.cmkLabel,
        classes.default,
        classes.labelDescription,
        classes.labelTitle,
        classes.labelTitleContainer,
        classes.link,
        getLocalizedLink,
        hasEntitlement,
        isUserEnterprise,
        translate,
    ]);

    if (loading) {
        return <div
            className={classes.centerLoader}
            data-cy='encryption-circular-progress'>
            <CircularProgress />
        </div>;
    }

    return (
        <div className={classes.mainContent}>
            {(EnableCustomerManagedKeyEntitlement ? !hasEntitlement : !isUserEnterprise) && <UiAlertBanner
                closeable={false}
                type='warning'>
                {translate(
                    { id: 'CLIENT_ENCRYPTION_UPGRADE_BANNER' },
                    {
                        link: (
                            <Link
                                target="_blank"
                                rel="noreferrer"
                                href={getLocalizedLink({ articleSlug: EncryptionLink })}
                                underline='none'
                            >
                                {translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_REVAMP' })}
                            </Link>
                        ),
                    },
                )}
            </UiAlertBanner>}
            {EnableCustomerManagedKeyErrorBanner && hasCmkError &&
                <ApAlertBar
                    style={{ marginBottom: '10px' }}
                    status={'error' as any}
                    cancelable={false}
                    data-cy='encryption-cmk-config-error'
                >
                    {translate({ id: 'CLIENT_CMK_EXPIRED' }, {
                        link: (
                            <Link
                                href={getLocalizedLink({ articleSlug: CustomerManagedKeyLink })}
                                target="_blank"
                                rel="noopener noreferrer"
                                underline='none'
                                data-cy="cmk-expired-docs-link"
                            >
                                {translate({ id: 'CLIENT_HERE' })}
                            </Link>
                        ),
                    })}
                </ApAlertBar>}
            <UiText
                id="encryptionTitle"
                className={classes.title}
                data-cy="encryption-title">
                {translate({ id: 'CLIENT_ENCRYPTION_TYPE' })}
            </UiText>
            <UiText
                className={classes.description}
                data-cy="encryption-description">
                {translate({ id: 'CLIENT_ENCRYPTION_DESCRIPTION' })}
            </UiText>
            <RadioGroup
                aria-labelledby='encryptionTitle'
                value={encryptionType}
                data-cy='encryption-radio-group'
                onChange={handleChange}
            >
                {options.map((option, i) => (
                    <FormControlLabel
                        disabled={option.disabled}
                        className={classes.radio}
                        key={i}
                        value={option.value}
                        control={<Radio />}
                        label={<>
                            {option.title}
                            {option.description}
                        </>}
                        data-cy={`encryption-radio-${option.dataCy}`}
                    />
                ))}
            </RadioGroup>
            {encryptionType === EncryptionType.CUSTOMER &&
            (EnableCustomerManagedKeyEntitlement ? !!hasEntitlement : isUserEnterprise) &&
            <Button
                className={classes.editButton}
                data-cy='encryption-edit-connection-button'
                onClick={editConnection}
                variant='outlined'>
                {translate({ id: 'CLIENT_EDIT_CONNECTION' })}
            </Button>}
            {EnableKMSClientCertificate && certMetadata && <Box className={classes.certMetdataBox}>
                <UiText className={classes.metdataDataDetails}>
                    {translate({ id: 'CLIENT_CERTIFICATE_DETAILS' })}
                </UiText>
                <TableContainer>
                    <Table style={{ tableLayout: 'fixed' }}>
                        <TableRow>
                            <TableCell
                                className={classes.metdataDataDetails}
                                width="20%">
                                {`${translate({ id: 'CLIENT_THUMBPRINT' })}:`}
                            </TableCell>
                            <TableCell
                                className={classes.metdataDataDetails}
                                width="80%">
                                {certMetadata.Thumbprint}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                className={classes.metdataDataDetails}
                                width="20%">
                                {`${translate({ id: 'CLIENT_SUBJECT_CN' })}:`}
                            </TableCell>
                            <TableCell
                                className={classes.metdataDataDetails}
                                width="80%">
                                {certMetadata.Subject}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                className={classes.metdataDataDetails}
                                width="20%">
                                {`${translate({ id: 'CLIENT_START_DATE' })}:`}
                            </TableCell>
                            <TableCell
                                className={classes.metdataDataDetails}
                                width="80%">
                                {certMetadata['Start date']?.toString()}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                className={classes.metdataDataDetails}
                                width="20%">
                                {`${translate({ id: 'CLIENT_EXPIRES_ON_CERT' })}:`}
                            </TableCell>
                            <TableCell
                                className={classes.metdataDataDetails}
                                width="80%">
                                {certMetadata.Expires?.toString()}
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>
            </Box>}
        </div>
    );
};

export default EncryptionComponent;
