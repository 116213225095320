import { AccountLicense } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { IPagination } from '@experiences/interfaces';
import { RobotAccountsEvent } from '@experiences/telemetry';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
    useUserReadableTime,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import {
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    ApTooltip,
    PortalCustomIcon,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { AboutAccountsLink } from '../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../common/constants/RouteNames';
import useSimpleGroup from '../../common/hooks/SimpleGroup';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { useDocumentationLinks } from '../../common/hooks/useDocumentationLink';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type { IRobot } from '../../common/interfaces/cis/robot';
import {
    deleteRobots,
    getRobots,
    robotUrl,
} from '../../services/identity/RobotAccountService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../store/selectors';
import { ApDataGridMoreRowActionsComponent } from '../common/ApDataGrid/ApDataGridMoreRowActionsComponent';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import UpgradeForFeature from '../common/UpgradeForFeature';

const useStyles = makeStyles(theme => ({
    truncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const RobotAccountPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const createDialog = useShowDialog();
    const { includesLicense } = useCheckLicense();
    const EnableRobotDisplayName = useFeatureFlagValue(Features.EnableRobotDisplayName.name);

    const { getErrorMessage } = useGetErrorInfo();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const getLocalizedLink = useDocumentationLinks();
    const { userReadableTime } = useUserReadableTime();

    const isAdmin = useSelector(isAdminSelector);
    const currentAccountGlobalId = useSelector(accountGlobalId);

    const {
        loading: groupLoading, getGroupsForUser,
    } = useSimpleGroup(true);

    const [ loading, setLoading ] = useState(false);
    const [ pagination, setPagination ] = useState<IPagination>({
        top: 25,
        skip: 0,
        searchTerm: '',
        sortBy: 'Name',
        sortOrder: 'desc',
    });
    const setErrorMessage = useCentralErrorSetter();

    const {
        data, mutate, isLoading: loadingRobots,
    } = useSWR(
        {
            url: robotUrl,
            pagination,
            partitionGlobalId: currentAccountGlobalId,
        },
        getRobots
    );

    useEffect(() => {
        setLoading(groupLoading);
    }, [ groupLoading ]);

    const deleteRobotsAsync = useCallback(async (robots: IRobot[]) => {
        try {
            if (robots.length > 0) {
                const robotIds = robots.map(robot => robot.id);
                await deleteRobots(currentAccountGlobalId, robotIds);
                mutate();
                createNotification(translate({ id: 'CLIENT_ROBOT_DELETED' }), notificationType.INPROGRESS);
            }
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        }
    }, [ currentAccountGlobalId, mutate, createNotification, translate, setErrorMessage, getErrorMessage ]);

    const onClickDeleteItem = useCallback(async (item?: IRobot | IRobot[]) => {
        if (!item) {
            return;
        }
        const robots = Array.isArray(item) ? item : [ item ];
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_DELETE_ROBOT' }),
            body: (
                <div>
                    <UiText>
                        {translate({ id: 'CLIENT_DELETE_ROBOT_ACCOUNT_WARNING' })}
                    </UiText>
                    <br />
                    <UiText>
                        {translate({ id: 'CLIENT_DELETE_ROBOT_ACCOUNT_CONFIRM_MESSAGE' })}
                    </UiText>
                </div>
            ),
            icon: 'error',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
        });
        if (proceed) {
            deleteRobotsAsync(robots);
        }
    }, [ translate, createDialog, deleteRobotsAsync ]);

    const onGridChange = useCallback(({
        filter, sort, pageIndex, pageSize, searchTerm,
    }: any) => {
        const newPaginationState: IPagination = {
            searchTerm: searchTerm ?? '',
            top: pageSize,
            skip: pageIndex * pageSize,
        };
        if (sort?.[0]?.field) {
            newPaginationState.sortBy = sort[0].field;
            newPaginationState.sortOrder = sort[0].direction === 'asc' ? 'asc' : 'desc';
        }
        setPagination(newPaginationState);
    }, [ setPagination ]);

    const onSortChange = useCallback((sort: any) => {
        setPagination(prev => ({
            ...prev,
            sortBy: sort.direction ? sort.field : undefined,
            sortOrder: sort.direction ? sort.direction : undefined,
        }));
    }, [ setPagination ]);

    if (includesLicense([ AccountLicense.FREEKIT ])) {
        return (
            <UpgradeForFeature
                upgradeTitle={translate({ id: 'CLIENT_UPGRADE_ROBOT_TITLE' })}
                upgradeMessage={translate({ id: 'CLIENT_UPGRADE_ROBOT_DESCRIPTION' })}
                documentationLink={getLocalizedLink({ articleSlug: AboutAccountsLink })}
                level={AccountLicense.PRO}
                telemetryTitle={RobotAccountsEvent.ClickedRobotAccounts} />
        );
    }

    return (
        <ApDataGridWrapper
            data={data?.results ?? []}
            refresh={mutate}
            loading={loading || loadingRobots}
            selectable
            onChange={onGridChange}
            sortChange={onSortChange}
            dataCy="robots-ap-grid">

            <ApDataGridHeader<IRobot> search>
                <ApDataGridHeaderButton<IRobot>
                    id='delete'
                    key='delete'
                    type='action'
                    buttonType='mat-flat-button'
                    visible={isAdmin}
                    color="warn"
                    text={translate({ id: 'CLIENT_DELETE' })}
                    label={translate({ id: 'CLIENT_DELETE' })}
                    onClick={onClickDeleteItem}
                    dataCy='ap-grid-delete-robot-button'
                />
                <ApDataGridHeaderButton<IRobot>
                    id='addRobot'
                    key='addRobot'
                    type='main'
                    buttonType='mat-flat-button'
                    visible={isAdmin}
                    color='primary'
                    text={translate({ id: 'CLIENT_ADD_ROBOT_ACCOUNT' })}
                    label={translate({ id: 'CLIENT_ADD_ROBOT_ACCOUNT' })}
                    onClick={() => navigate(`${getRoute(RouteNames.Robots)}/add`)}
                    dataCy='ap-grid-add-robot-button'
                />
            </ApDataGridHeader>

            <ApDataGridColumn<IRobot>
                property={EnableRobotDisplayName ? 'DisplayName' : 'Name'}
                title={translate({ id: 'CLIENT_NAME' })}
                width={20}
                sortable
                render={(item) => {
                    const robotName = EnableRobotDisplayName ? (item.displayName || item.name) : item.name;
                    return <UiStack
                        gap={SpacingToken.Micro}
                        align='center'>
                        <ApTooltip content={translate({ id: 'CLIENT_ROBOT' })}>
                            <PortalCustomIcon
                                name='robot'
                                size='16px'
                                aria-label={translate({ id: 'CLIENT_ROBOT' })} />
                        </ApTooltip>
                        <ApTooltip content={robotName}>
                            <div className={classes.truncate}>
                                {robotName}
                            </div>
                        </ApTooltip>
                    </UiStack>;
                }}
            />

            <ApDataGridColumn<IRobot>
                property="groups"
                title={translate({ id: 'CLIENT_GROUP_MEMBERSHIPS' })}
                width={30}
                render={(item) => {
                    const groups = getGroupsForUser(item.groupIds)?.join(', ');
                    return groups ? (
                        <ApTooltip
                            content={groups}
                            placement="bottom">
                            <div className={classes.truncate}>
                                {groups}
                            </div>
                        </ApTooltip>
                    ) : (
                        <span>
                            {translate({ id: 'CLIENT_NO_GROUPS_FOUND' })}
                        </span>
                    );
                }}
            />

            <ApDataGridColumn<IRobot>
                property="CreationTime"
                title={translate({ id: 'CLIENT_LAST_ACTIVE' })}
                width={30}
                sortable
                render={(item) => {
                    if (!item.lastLoginTime) {
                        return <>
                            {translate({ id: 'CLIENT_LAST_ACTIVE_NEVER' })}
                        </>;
                    }

                    const date = new Date(item.lastLoginTime);
                    const dateOptions: Intl.DateTimeFormatOptions = {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    };
                    const readableTime = userReadableTime(item.lastLoginTime);

                    return (
                        readableTime ? <ApTooltip content={date.toLocaleDateString(undefined, dateOptions)}>
                            <UiText>
                                {readableTime}
                            </UiText>
                        </ApTooltip> : <FormattedDate
                            value={date}
                            {...dateOptions} />
                    );
                }}
            />

            {isAdmin && (
                <ApDataGridRowActions>
                    <ApDataGridRowButton<IRobot>
                        id='more-actions'
                        label={translate({ id: 'CLIENT_MORE_ACTIONS' })}
                        render={row => (
                            <ApDataGridMoreRowActionsComponent<IRobot>
                                row={row}
                                actions={[
                                    {
                                        id: 'edit',
                                        label: translate({ id: 'CLIENT_EDIT' }),
                                        click: (item) => navigate(`${getRoute(RouteNames.Robots)}/edit`, { state: { robot: item } }),
                                        type: ButtonType.Button,
                                        actionType: GridActionType.Row,
                                    },
                                    {
                                        id: 'delete',
                                        label: translate({ id: 'CLIENT_DELETE' }),
                                        click: onClickDeleteItem,
                                        type: ButtonType.Button,
                                        actionType: GridActionType.Row,
                                    },
                                ]}
                            />
                        )}
                    />
                </ApDataGridRowActions>
            )}

            <ApDataGridFooter
                length={data?.totalCount ?? 0}
                pageSizes={[ 5, 10, 25, 50 ]}
            />

        </ApDataGridWrapper>
    );
};

export default RobotAccountPageComponent;
