import { Entitlements } from '@experiences/constants';
import type { IProductConfiguration } from '@experiences/util';
import {
    getSortedRobotsAndServicesProductConfigurations,
    getSortedUserBundleConfigurations,
} from '@experiences/util';

import type {
    IAccountProductDto,
    IOrganizationLicenseGroup,
    IOrganizationLicenseProduct,
    IOrganizationLicenseWithAvailableProducts,
    IProductAvailability,
} from '../interfaces/license';

function isAvailable(productInHostLicense?: IProductAvailability, productInHostedLicense?: IAccountProductDto) {
    return (
        (!!productInHostLicense && productInHostLicense!.totalQuantity > 0) ||
        (!!productInHostedLicense && productInHostedLicense?.quantity > 0)
    );
}

export function buildProductQuantitiesMap(
    organizationLicenseAndAvailableProducts: IOrganizationLicenseWithAvailableProducts,
    isStandaloneLicense: boolean = false,
): { [code: string]: string | number | undefined } {
    const availableHostProducts = organizationLicenseAndAvailableProducts!.productsWithAvailability;
    const productsInOrganizationLicense = new Map(
        organizationLicenseAndAvailableProducts!.license
            ? organizationLicenseAndAvailableProducts!.license?.products.map((p): [string, IAccountProductDto] => [
                p.code,
                p,
            ])
            : [],
    );
    const currentProductQuantities: { [code: string]: string | number | undefined } = {};
    if (isStandaloneLicense) {
        organizationLicenseAndAvailableProducts.license?.products.forEach(
            p => (currentProductQuantities[p.code] = p.quantity),
        );
    } else {
        availableHostProducts?.forEach(p => {
            currentProductQuantities[p.code] = productsInOrganizationLicense.get(p.code)?.quantity ?? 0;
        });
    }
    return currentProductQuantities;
}

function mapProductsQuantityAndAvailability(
    orderedProductsList: IProductConfiguration[],
    productsInOrganizationLicense: Map<string, IAccountProductDto>,
    productsInHostLicense: Map<string, IProductAvailability>,
): IOrganizationLicenseProduct[] {
    return orderedProductsList
        .filter(pc => isAvailable(productsInHostLicense.get(pc.code), productsInOrganizationLicense.get(pc.code)))
        .map(pc => ({
            code: pc.code,
            type: productsInHostLicense.get(pc.code)?.type ?? '',
            available: productsInHostLicense.get(pc.code)?.availableQuantity ?? 0,
            quantity: productsInOrganizationLicense.get(pc.code)?.quantity ?? 0,
            consumedQuantity: productsInHostLicense.get(pc.code)?.consumedQuantity ?? 0,
        }));
}

export function groupLicensesByType(
    organizationLicenseAndAvailableProducts: IOrganizationLicenseWithAvailableProducts,
    isStandalone: boolean = false,
): IOrganizationLicenseGroup[] {
    const productsInOrganizationLicense = new Map(
        organizationLicenseAndAvailableProducts!.license
            ? organizationLicenseAndAvailableProducts!.license?.products?.map((p): [string, IAccountProductDto] => [
                p.code,
                p,
            ])
            : [],
    );

    const allAvailableProducts = isStandalone
        ? new Map(
            organizationLicenseAndAvailableProducts!.license?.products?.map((p): [string, IProductAvailability] => [
                p.code,
                {
                    availableQuantity: p.quantity,
                    code: p.code,
                    totalQuantity: p.quantity,
                    type: p.type,
                    consumedQuantity: 0,
                },
            ]),
        )
        : new Map(
            organizationLicenseAndAvailableProducts!.productsWithAvailability?.map(
                (p): [string, IProductAvailability] => [ p.code, p ],
            ),
        );

    const robotsAndServicesProducts = mapProductsQuantityAndAvailability(
        getSortedRobotsAndServicesProductConfigurations(),
        productsInOrganizationLicense,
        allAvailableProducts,
    );
    const userProducts = mapProductsQuantityAndAvailability(
        getSortedUserBundleConfigurations(),
        productsInOrganizationLicense,
        allAvailableProducts,
    );

    return [
        {
            id: 'USERS',
            products: userProducts,
        },
        {
            id: 'ROBOTS_SERVICES',
            products: robotsAndServicesProducts,
        },
    ];
}

export const sendPendoTrackEventForAKit = (
    currentLicenses: string[] | undefined,
    licensesToAdd: string[],
) => {
    try {
        if (!currentLicenses?.some(p => p === 'AKIT') && licensesToAdd.includes('AKIT')) {
            window.PortalShell.Pendo?.trackEvent('akit_license_allocation');
        }
    } catch (error) {
        // Ignore
    }
};

function isSapEnabled(): boolean {
    const entitlements = window.PortalShell?.AccountAndTenants.getCurrentAccountAndTenantsInfo()?.organization?.entitlements;
    if (!entitlements) {
        return false;
    }
    return Array.isArray(entitlements) && entitlements.includes(Entitlements.PlatformSapEnbled);
}

export function hasSapClientDescriptionOverride(productCode: string): boolean {
    return isSapEnabled() && productCode === 'RPADEVPRONU';
}
