import { useUiDataContext } from '@experiences/ui-common';
import type { ListRange } from '@uipath/apollo-angular-elements';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';

import {
    getAuditEvents,
    getAuditSources,
    organizationAuditUrl,
    tenantAuditUrl,
} from '../../../services/audit/AuditService';
import type {
    AuditEventQueryResultDto,
    AuditEventTargetDto,
    IAuditDetailsContext,
} from '../interfaces/auditLog';
import { useAuditGridFilterModel } from './AuditGridFilterModel';
import { getNextEvents } from './AuditGridUtils';

export function useAuditGridViewModel() {
    const { tenantId } = useParams() as { tenantId: string };

    const filterModel = useAuditGridFilterModel();

    const {
        isClearing, userFilter, sourceFilter, categoryFilter, actionFilter, timeFilter, statusFilter,
    } = filterModel;

    const { data: { virtualScrollManager } } = useUiDataContext<IAuditDetailsContext>();

    const previousRef = useRef<string | null>(null);

    const [ events, setAuditEvents ] = useState<any>([]);

    const setInitialAuditRowEvents = (results: AuditEventQueryResultDto) => {
        if (!results.auditEvents) {
            return;
        }

        setAuditEvents(virtualScrollManager?.getInfiniteDataList(results.auditEvents, 0, results.previous == null));

        return results;
    };

    const {
        data, isLoading: eventsLoading, isValidating: validating,
    } = useSWR(virtualScrollManager && !isClearing ? {
        url: tenantId ? `/${tenantId}/${tenantAuditUrl}/query/events` : `${organizationAuditUrl}/query/events`,
        userFilter: userFilter.debouncedValue,
        sourceFilter: sourceFilter.debouncedValue,
        categoryFilter: categoryFilter.debouncedValue,
        actionFilter: actionFilter.debouncedValue,
        statusFilter: statusFilter.debouncedValue,
        timeFilter: timeFilter.value,
    } : null, (args) => getAuditEvents(args).then(setInitialAuditRowEvents));

    const {
        data: sources, isLoading: sourcesLoading,
    } = useSWR({ url: tenantId ? `/${tenantId}/${tenantAuditUrl}/query/sources` : `${organizationAuditUrl}/query/sources` }, getAuditSources);

    const categories = useMemo(() => sources?.flatMap(s => s.eventTargets) as AuditEventTargetDto[] | undefined, [ sources ]);

    const refresh = useCallback(async () => {
        // to refresh the data, we set the "to" date to the current time, this will trigger a re-fetch
        filterModel.timeFilter.set({
            from: filterModel.timeFilter.value.from,
            to: new Date(),
        });
    }, [ filterModel.timeFilter ]);

    const onRangeLoaded = useCallback(async (range: ListRange) => {
        // if we are at the start of the list or if there is no more data, we don't need to load more data
        if (range.start === 0 || !previousRef.current) {
            return;
        }

        const nextEvents = await getNextEvents(range.start, previousRef.current);

        // save the previous pointer for the next load
        const previous = nextEvents?.previous ?? null;
        previousRef.current = previous;

        setAuditEvents(virtualScrollManager?.getInfiniteDataList(nextEvents?.auditEvents ?? [], range.start, previous == null));
    }, [ virtualScrollManager ]);

    useEffect(() => {
        virtualScrollManager?.scrollTo(0);

        virtualScrollManager?.rangeLoad({
            start: 0,
            end: 0,
        });
    }, [ virtualScrollManager ]);

    useEffect(() => {
        if (!virtualScrollManager || !data) {
            return;
        }

        // on initial load of data, we save the previous pointer
        previousRef.current = data?.previous ?? null;

        // setup subscription for subsequent loads based on scroll position
        const subscription = virtualScrollManager.rangeLoad$.subscribe(onRangeLoaded);

        return () => subscription.unsubscribe();
    }, [ virtualScrollManager, onRangeLoaded, data ]);

    return {
        ...filterModel,
        events,
        sources,
        categories,
        refresh,
        validating,
        loading: eventsLoading || sourcesLoading,
    };
}
