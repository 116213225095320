/* eslint-disable max-len */
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    Entity,
    type IPagination,
} from '@experiences/interfaces';
import {
    portalTelemetry,
    SeverityLevel,
    UsersEvent,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import {
    getDisplayName,
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
    useUserReadableTime,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    ApLink,
    ApTooltip,
    PortalCustomIcon,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import urljoin from 'url-join';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import {
    USER_TYPE_ICON,
    UserType,
} from '../../common/constants/UsersConstant';
import useSimpleGroup from '../../common/hooks/SimpleGroup';
import { useCheckAADEnabled } from '../../common/hooks/useCheckAADEnabled';
import { useCheckSSOEnabled } from '../../common/hooks/useCheckSSOEnabled';
import { useLoginProvider } from '../../common/hooks/useLoginProvider';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type { IUserCIS } from '../../common/interfaces/cis/user';
import {
    checkIfOnlyUserInGroup,
    groupUrl,
} from '../../services/identity/GroupService';
import { createInviteUrl } from '../../services/identity/UserInvite';
import getUsersInPartition, {
    getUsersInPartitionWithLicenses,
    userPartitionUrl,
} from '../../services/identity/UserPartitionService';
import { deleteUsers } from '../../services/identity/UserService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    companyName,
    EnableUserLicensingSelector,
    isAdminSelector,
    isHostModeSelector,
    isUnlicensedSelector,
    organizationLanguage,
    userGlobalId,
} from '../../store/selectors';
import { ApDataGridMoreRowActionsComponent } from '../common/ApDataGrid/ApDataGridMoreRowActionsComponent';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import { UserGroup } from '../common/UserGroups';
import type { IUserWithLicenses } from './profile/ProfileConstants';
import { getUserTypeIconTranslationKey } from './profile/ProfileUtils';
import ResendInvitationDialogBody from './subcomponents/ResendInvitationDialogBody';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    adminChip: {
        fontSize: '11px',
        fontWeight: 'bold',
        marginLeft: '8px',
        backgroundColor: theme.palette.semantic.colorBackgroundHigh,
        height: '18px',
        marginTop: '1px',
        '&:hover': { backgroundColor: theme.palette.semantic.colorBackgroundHigh },
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
    azureDescription: {
        marginTop: '8px',
        marginBottom: '8px',
    },
    resendButton: {
        marginLeft: '8px',
        fontWeight: 600,
        color: theme.palette.semantic.colorPrimary,
        backgroundColor: 'transparent',
        borderWidth: '0px',
        cursor: 'pointer',
        '&:hover': { textDecoration: 'underline' },
    },
    licenseIcon: {
        width: '12px',
        height: '12px',
    },
    truncate: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
}));

const UsersCISPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const { getErrorMessage } = useGetErrorInfo();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const userId = useSelector(userGlobalId);
    const isAdmin = useSelector(isAdminSelector);
    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const orgLanguage = useSelector(organizationLanguage);
    const orgName = useSelector(companyName);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const isHostMode = useSelector(isHostModeSelector);
    const subscriptionPlan = useSelector(accountType);

    const { loading: groupLoading } = useSimpleGroup(true);

    const isSSOConnectionEnabled = useCheckSSOEnabled();
    const isAADConnectionEnabled = useCheckAADEnabled();
    const createDialog = useShowDialog();
    const loginProvider = useLoginProvider();
    const setErrorMessage = useCentralErrorSetter();
    const { userReadableTime } = useUserReadableTime();

    const {
        data: lastAdminInOrg, isValidating,
    } = useSWR(
        !process.buildConfigs.disableUsersLeaveOrg && isAdmin && !isSSOConnectionEnabled
            ? {
                url: `${groupUrl}/:id/checkIfOnlyUserInGroup`,
                partitionGlobalId,
                userGlobalId: userId,
                groupId: UserGroup.Administrators,
            }
            : null,
        checkIfOnlyUserInGroup,
    );

    const [ loading, setLoading ] = useState(false);
    const [ disabledEmails, setDisabledEmails ] = React.useState<string[]>([]);

    const dataUrl = useMemo(
        () => (EnableUserLicensing && isAdmin ? `${userPartitionUrl}/licenses` : `${userPartitionUrl}/users`),
        [ EnableUserLicensing, isAdmin ],
    );
    const [ pagination, setPagination ] = useState<IPagination>({
        top: 25,
        skip: 0,
        searchTerm: '',
        sortBy: 'Name',
        sortOrder: 'desc',
    });

    const {
        data, mutate, isLoading: loadingUsers,
    } = useSWR(
        {
            url: dataUrl,
            pagination,
            partitionGlobalId,
        },
        EnableUserLicensing && isAdmin ? getUsersInPartitionWithLicenses : getUsersInPartition
    );

    useEffect(() => {
        setLoading(groupLoading || isValidating);
    }, [ groupLoading, isValidating ]);

    const deleteUsersAsync = useCallback(
        async (users: IUserCIS[], deleteCurrentUser: boolean = false) => {
            try {
                if (users.length > 0) {
                    const userIds = users.map(user => user.id);
                    createNotification(translate({ id: 'CLIENT_USER_DELETED' }), notificationType.INPROGRESS);
                    await deleteUsers(partitionGlobalId, userIds, deleteCurrentUser, isHostMode);

                    createNotification(
                        translate({ id: 'CLIENT_SUCCESSFULLY_DELETED_USERS' }),
                        notificationType.SUCCESS
                    );

                    portalTelemetry.trackEvent({
                        name: UsersEvent.Delete,
                        properties: {
                            Message: 'INV-USERS-05 - User(s) deleted',
                            Code: 'INV-USERS-05',
                            SubscriptionType: subscriptionPlan,
                            NoOfDeletedUsers: (users.length).toString(),
                        },
                    });

                    mutate();
                }
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            }
        },
        [ createNotification, translate, partitionGlobalId, isHostMode, subscriptionPlan, mutate, setErrorMessage, getErrorMessage ],
    );

    const openDeleteDialog = useCallback(
        async (items?: IUserCIS[] | IUserCIS) => {
            if (!items) {
                return;
            }
            const users: IUserCIS[] = Array.isArray(items) ? items : [ items ];

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_USER' }),
                body: `${translate(
                    { id: 'CLIENT_SHOULD_DELETE_USERS' },
                    { 0: users.length === 1 ? getDisplayName(users[0]) : translate({ id: 'CLIENT_SELECTED_USERS_TEXT' }) },
                )} ${translate({ id: 'CLIENT_MULTIPLE_CHECKED_USER_DELETE' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            });
            if (proceed) {
                const filteredUsers = users.filter(user => user.id !== userId);
                deleteUsersAsync(filteredUsers);
            }
        },
        [ translate, createDialog, deleteUsersAsync, userId ],
    );

    const openLeaveOrgDialog = useCallback(
        async (user: IUserCIS) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_LEAVE_ORG' }),
                body: `${translate({ id: 'CLIENT_SHOULD_LEAVE_ORG' }, { name: orgName })}${translate({ id: 'CLIENT_SINGLE_USER_DELETE_SUFFIX' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
            });
            if (proceed) {
                try {
                    await deleteUsersAsync([ user ], true);
                    createNotification(translate({ id: 'CLIENT_USER_LEAVE_ORG' }), notificationType.INPROGRESS);
                    navigate('/portal_/logout');
                } catch (error) {
                    createNotification(translate({ id: 'CLIENT_USER_LEAVE_FAILED' }), notificationType.ERROR);
                }
            }
        },
        [ createDialog, translate, orgName, deleteUsersAsync, createNotification, navigate ],
    );

    const openAdminLeaveOrgDialog = useCallback(
        async (user: IUserCIS) => {
            if (!lastAdminInOrg) {
                openLeaveOrgDialog(user);
            } else {
                await createDialog({
                    title: translate({ id: 'CLIENT_UNABLE_LEAVE_ORG' }),
                    body: translate({ id: 'CLIENT_LEAVE_ORG_MIN_ONE_ADMIN' }),
                    icon: 'error',
                    primaryButtonText: 'OK',
                });
            }
        },
        [ translate, createDialog, openLeaveOrgDialog, lastAdminInOrg ],
    );

    const openResendInviteDialog = useCallback(async (user: IUserCIS) => {
        const inviteUrl = createInviteUrl(
            window.location.origin,
            partitionGlobalId,
            user.email,
            loginProvider,
            orgName,
        );

        await createDialog({
            title: translate({ id: 'CLIENT_RESEND_INVITE' }),
            customDialogContent: ResendInvitationDialogBody,
            customDialogContentProps: {
                user,
                orgLanguage,
                orgName,
                disabledEmails,
                setDisabledEmails,
                loginProvider,
                inviteUrl,
            },
        });
    }, [ createDialog, translate, partitionGlobalId, disabledEmails, orgLanguage, orgName, loginProvider ]);

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableAzureB2CAuthentication = useFeatureFlagValue(Features.EnableAzureB2CAuthentication.name);

    const ShowInviteUserButton = DisableFeatureFedRamp ? EnableAzureB2CAuthentication : true;

    const EnableUserInvite = useMemo(
        () =>
            !isUnlicensedMode && !process.buildConfigs.disableUserInvite && ShowInviteUserButton,
        [ ShowInviteUserButton, isUnlicensedMode ],
    );
    const handleInviteUser = useCallback(() => {
        navigate(`${getRoute(RouteNames.Users)}/invite`);
    }, [ getRoute, navigate ]);

    const handleBulkInviteUsers = useCallback(() => {
        portalTelemetry.trackTrace({
            message: `Clicked on Upload CSV button`,
            severityLevel: SeverityLevel.Info,
        });
        navigate(`${getRoute(RouteNames.Users)}/bulkinvite`);
    }, [ getRoute, navigate ]);

    const onGridChange = useCallback(({
        filter, sort, pageIndex, pageSize, searchTerm,
    }: any) => {
        const newPaginationState: IPagination = {
            searchTerm: searchTerm ?? '',
            top: pageSize ?? 25,
            skip: pageIndex * pageSize || 0,
        };
        if (sort?.[0]?.field) {
            newPaginationState.sortBy = sort[0].field;
            newPaginationState.sortOrder = sort[0].direction === 'asc' ? 'asc' : 'desc';
        }
        setPagination(newPaginationState);
    }, [ setPagination ]);

    const onSortChange = useCallback((sort: any) => {
        setPagination(prev => ({
            ...prev,
            sortBy: sort.direction ? sort.field : undefined,
            sortOrder: sort.direction ? sort.direction : undefined,
        }));
    }, [ setPagination ]);

    const getGroupMembershipsUrl = useCallback(
        (user: IUserCIS) => routePaths.adminIdentitiesProfileGroupMemberships.replace(':id', user.id).replace(':entity', Entity.USERS),
        []
    );

    const gridRowMenuActions = useMemo(() => (
        <ApDataGridRowActions>
            <ApDataGridRowButton<IUserWithLicenses>
                id="more-actions"
                label={translate({ id: 'CLIENT_MORE_ACTIONS' })}
                render={row => (
                    <ApDataGridMoreRowActionsComponent<IUserWithLicenses>
                        row={row}
                        actions={[
                            {
                                id: 'show-details',
                                label: translate({ id: 'CLIENT_SHOW_DETAILS' }),
                                click: (r) => navigate(getGroupMembershipsUrl(r)),
                                type: ButtonType.Button,
                                actionType: GridActionType.Row,
                                dataCy: 'show-details-button',
                            },
                            row.id !== userId || process.buildConfigs.disableUsersLeaveOrg ? {
                                id: 'delete',
                                label: translate({ id: 'CLIENT_DELETE' }),
                                click: openDeleteDialog,
                                disable: (r) => r?.type === UserType.DirectoryUser || r?.type === UserType.DirectoryGroup || r?.id === userId,
                                type: ButtonType.Button,
                                actionType: GridActionType.Row,
                                dataCy: 'delete-button',
                            } : {
                                id: 'leave-org',
                                label: translate({ id: 'CLIENT_LEAVE_ORG' }),
                                click: isAdmin ? openAdminLeaveOrgDialog : openLeaveOrgDialog,
                                type: ButtonType.Button,
                                actionType: GridActionType.Row,
                                dataCy: 'leave-org-button',
                            },
                        ]}
                    />
                )} />
        </ApDataGridRowActions>
    ), [
        isAdmin,
        navigate,
        openAdminLeaveOrgDialog,
        openDeleteDialog,
        openLeaveOrgDialog,
        translate,
        userId,
        getGroupMembershipsUrl,
    ]);

    return (
        <>
            {isAADConnectionEnabled && (
                <UiText className={classes.azureDescription}>
                    {translate({
                        id: DisableFeatureFedRamp || process.buildConfigs.isOnPrem
                            ? 'CLIENT_AZURE_USERS_DESCRIPTION_NO_INVITE'
                            : 'CLIENT_AZURE_USERS_DESCRIPTION',
                    })}
                </UiText>
            )}
            <ApDataGridWrapper
                data={data?.results ?? []}
                refresh={mutate}
                loading={loading || loadingUsers}
                selectable
                onChange={onGridChange}
                sortChange={onSortChange}
                dataCy="users-grid">

                <ApDataGridHeader<IUserWithLicenses> search>
                    <ApDataGridHeaderButton<IUserWithLicenses>
                        id='delete-users'
                        key='delete-users'
                        type='action'
                        buttonType='mat-flat-button'
                        visible={isAdmin}
                        color="warn"
                        text={translate({ id: 'CLIENT_DELETE' })}
                        label={translate({ id: 'CLIENT_DELETE' })}
                        onClick={openDeleteDialog}
                        dataCy='delete-users-button'
                    />
                    <ApDataGridHeaderButton<IUserWithLicenses>
                        id='add-user'
                        key='add-user'
                        type='main'
                        buttonType='mat-flat-button'
                        visible={isAdmin && (process.buildConfigs.name === 'msi' || process.buildConfigs.name === 'k8s')}
                        color='primary'
                        text={translate({ id: 'CLIENT_ADD_USER' })}
                        label={translate({ id: 'CLIENT_ADD_USER' })}
                        onClick={handleBulkInviteUsers}
                        dataCy='add-user-button'
                    />
                    <ApDataGridHeaderButton<IUserWithLicenses>
                        id='bulk-invite-users'
                        key='bulk-invite-users'
                        type='main'
                        buttonType='mat-stroked-button'
                        visible={isAdmin && EnableUserInvite}
                        color='primary'
                        text={translate({ id: 'CLIENT_BULK_INVITE' })}
                        label={translate({ id: 'CLIENT_BULK_INVITE' })}
                        onClick={handleBulkInviteUsers}
                        dataCy='bulk-invite-users-button'
                    />
                    <ApDataGridHeaderButton<IUserWithLicenses>
                        id='invite-users'
                        key='invite-users'
                        type='main'
                        buttonType='mat-flat-button'
                        visible={isAdmin && EnableUserInvite}
                        color='primary'
                        text={translate({ id: 'CLIENT_INVITE_USER' })}
                        label={translate({ id: 'CLIENT_INVITE_USER' })}
                        onClick={handleInviteUser}
                        dataCy='invite-users-button'
                    />
                </ApDataGridHeader>

                {!!process.buildConfigs.enableUsernameColumn && (
                    <ApDataGridColumn<IUserWithLicenses>
                        property="UserName"
                        title={translate({ id: 'CLIENT_USERNAME' })}
                        width={25}
                        sortable
                        render={(item) => (
                            <UiStack
                                direction="row"
                                gap={SpacingToken.Micro}
                                pb={2}
                                pt={2}
                                align="center">
                                <ApTooltip content={translate({ id: getUserTypeIconTranslationKey(item.type) })}>
                                    <PortalCustomIcon
                                        name={USER_TYPE_ICON[item.type ?? 0]}
                                        aria-label={translate({ id: getUserTypeIconTranslationKey(item.type) })} />
                                </ApTooltip>
                                <ApLink
                                    data-cy="username-link"
                                    href={urljoin(origin, accountName, 'portal_', getGroupMembershipsUrl(item))}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(getGroupMembershipsUrl(item));
                                    }}>
                                    <UiText variant={FontVariantToken.fontSizeM}>
                                        {item.userName}
                                    </UiText>
                                </ApLink>
                            </UiStack>
                        )}
                    />
                )}
                {!!process.buildConfigs.enableUsernameColumn && (
                    <ApDataGridColumn<IUserWithLicenses>
                        property="Name"
                        title={translate({ id: 'CLIENT_NAME' })}
                        width="20"
                        sortable
                        render={(item) =>
                            <ApTooltip content={getDisplayName(item)}>
                                {getDisplayName(item)}
                            </ApTooltip>}
                    />
                )}

                {!process.buildConfigs.enableUsernameColumn && (
                    <ApDataGridColumn<IUserWithLicenses>
                        property="UserName"
                        title={translate({ id: 'CLIENT_NAME' })}
                        width={25}
                        sortable
                        render={(item) => (
                            <UiStack
                                direction="row"
                                gap={SpacingToken.Micro}
                                pb={2}
                                pt={2}
                                align='center'>
                                <ApTooltip content={translate({ id: getUserTypeIconTranslationKey(item.type) })}>
                                    <PortalCustomIcon
                                        name={USER_TYPE_ICON[item.type ?? 0]}
                                        aria-label={translate({ id: getUserTypeIconTranslationKey(item.type) })} />
                                </ApTooltip>
                                <ApLink
                                    data-cy="name-link"
                                    href={urljoin(origin, accountName, 'portal_', getGroupMembershipsUrl(item))}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(getGroupMembershipsUrl(item));
                                    }}>
                                    <UiText variant={FontVariantToken.fontSizeM}>
                                        {getDisplayName(item)}
                                    </UiText>
                                </ApLink>
                            </UiStack>
                        )}
                    />
                )}

                <ApDataGridColumn<IUserWithLicenses>
                    property="Email"
                    title={translate({ id: 'CLIENT_EMAIL' })}
                    width={25}
                    sortable
                    render={(item) => <ApTooltip content={item.email}>
                        {item.email}
                    </ApTooltip>}
                />

                <ApDataGridColumn<IUserWithLicenses>
                    property="LastLoginTime"
                    title={translate({ id: 'CLIENT_LAST_LOGIN' })}
                    width={25}
                    sortable
                    render={(item) => {
                        if (!item.lastLoginTime || new Date(item.lastLoginTime).getTime() <= 0) {
                            if (!process.buildConfigs.disableUserInvite &&
                                item.type !== UserType.DirectoryUser &&
                                !DisableFeatureFedRamp) {
                                return (
                                    <span>
                                        {isAdmin ? translate({ id: 'CLIENT_PENDING' }) : translate({ id: 'CLIENT_PENDING_INVITATION' })}
                                        {isAdmin && (
                                            <ApTooltip content={translate({ id: 'CLIENT_RESEND' })}>
                                                <button
                                                    className={classes.resendButton}
                                                    data-cy="resend-invite-button"
                                                    onClick={() => openResendInviteDialog(item)}
                                                >
                                                    {translate({ id: 'CLIENT_RESEND' })}
                                                </button>
                                            </ApTooltip>
                                        )}
                                    </span>
                                );
                            }
                            return <></>;
                        }

                        const date = new Date(item.lastLoginTime);
                        const dateOptions: Intl.DateTimeFormatOptions = {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        };
                        const readableTime = userReadableTime(item.lastLoginTime);

                        return (
                            readableTime ? <ApTooltip content={date.toLocaleDateString(undefined, dateOptions)}>
                                <UiText>
                                    {readableTime}
                                </UiText>
                            </ApTooltip> : <FormattedDate
                                value={date}
                                {...dateOptions} />
                        );
                    }}
                />

                {gridRowMenuActions}

                <ApDataGridFooter
                    length={data?.totalCount ?? 0}
                    pageSizes={[ 5, 10, 25, 50 ]}
                />

            </ApDataGridWrapper>
        </>

    );
};

export default UsersCISPageComponent;
