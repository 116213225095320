/* eslint-disable react/no-unstable-nested-components */
import { AccountLicense } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    AdminInsightsExportEvent,
    portalTelemetry,
    ServiceEvent,
    SeverityLevel,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import {
    SpacingToken,
    UiStack,
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@mui/styles';
import {
    ApButton,
    PortalCustomIcon,
    PortalIcon,
} from '@uipath/portal-shell-react';
import clsx from 'clsx';
import { produce } from 'immer';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import {
    createSearchParams,
    useParams,
} from 'react-router-dom';
import useSWR from 'swr';
import urljoin from 'url-join';

import useCheckAuthTypes from '../../../auth/hooks/CheckAuthType';
import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { getFriendlyName } from '../../../common/constants/ServicesMapping';
import useApiAccessInstanceModal from '../../../common/hooks/useApiAccessInstanceModal';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useEnabledTenantOperations } from '../../../common/hooks/useEnabledTenantOperations';
import { useTenantOperations } from '../../../common/hooks/useTenantOperations';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { ServiceErrorCode } from '../../../common/interfaces/error';
import type {
    IService,
    ITenant,
} from '../../../common/interfaces/tenant/tenant';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../common/providers/BreadcrumbProvider';
import AllocateLicenseIcon from '../../../images/icons/AllocateLicenseIcon';
import { getTenantServiceLicenses } from '../../../services/licensing/LicenseManagementService';
import type { IServiceError } from '../../../services/organization/TenantService';
import {
    entitlementsKey,
    getAvailableServices,
    getEntitlements,
    getTenantById,
    tenantAvailableServicesUri,
    tenantByIdUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    isAdminSelector,
    isOrgAdminSelector,
    isUnlicensedSelector,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import type { ICardAction } from '../../common/UiCard';
import {
    UiMoreActionsComponent,
    UiServiceCard,
} from '../../common/UiCard';
import UiCardGrid from '../../common/UiCardGrid/UiCardGrid';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import { UiRefreshButton } from '../../common/UiRefreshButton';
import { UiTenantStatusBanner } from '../../common/UiTenantStatusBanner';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import { addHiddenDependenciesHelper } from '../subcomponents/helpers/AddHiddenDependenciesHelper';
import { getHiddenServices } from '../subcomponents/helpers/HiddenServicesHelper';
import {
    isUserManagementEnabled,
    returnExternalServiceManageUsersUrl,
} from '../subcomponents/helpers/ManageUserHelper';
import {
    getListOfDependencies,
    getListOfHiddenDependencies,
    getListOfParents,
} from '../subcomponents/helpers/ServiceDependencyGraph';
import { useServiceDependency } from '../subcomponents/helpers/useServiceDependency';
import ServiceDeleteDialogBody from '../subcomponents/ServiceDeleteDialogBody';
import { TenantStatusConstants } from '../TenantConstants';
import { useTenantOperationTrackerContext } from '../TenantOperationTrackerContextProvider';
import { useTenantsContext } from '../TenantsContextProvider';
import { listOfServices } from './HelperUtil';
import useTenantServicesViewModel from './TenantServicesViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        ...GlobalStyles(theme),
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
        },
        link: { marginLeft: '8px' },
        spacerText: { marginBottom: '24px' },
        dependencyText: { fontStyle: 'italic' },
        licenseDisabled: {
            '& .iconStroke': { stroke: theme.palette.semantic.colorBorder },
            '& .iconFill': { fill: theme.palette.semantic.colorBorder },
            '& .iconStrokeAndFill': {
                stroke: theme.palette.semantic.colorBorder,
                fill: theme.palette.semantic.colorBorder,
            },
        },
    }),
);

const getTenantConfigureServiceRoute = (
    tenantId: string,
    type: 'configure' | 'enable' | 'addLicenses',
    service?: string) =>
    `${RouteNames.TenantConfigureService}/:serviceType`
        .replace(':tenantId', tenantId)
        .replace(':type', type)
        .replace(':serviceType', service ?? '');

const TenantServicesComponent: React.FC = () => {
    const getRoute = useRouteResolver();
    const createDialog = useShowDialog();
    const setErrorMessage = useCentralErrorSetter();
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigateWithParams();
    const tenantOperations = useTenantOperations();
    const { logEvent } = useTelemetryHelper();

    const { formatMessage: translate } = useIntl();
    const {
        data: tenantContextData, changeServiceStatus,
    } = useTenantsContext();
    const { servicesToHide } = useServiceDependency();
    const { checkTokenTypeIsAuth0 } = useCheckAuthTypes();
    const { isUnlicensed } = useCheckLicense();
    const {
        ApiAccessInstanceModalComponent, setCurrentTenant,
    } = useApiAccessInstanceModal();
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const { refreshAfterComplete } = useTenantOperationTrackerContext();

    const logicalName = useSelector(accountLogicalName);
    const accountId = useSelector(accountGlobalId);
    const subscriptionType = useSelector(accountType);
    const isAdmin = useSelector(isAdminSelector);
    const isOrgAdmin = useSelector(isOrgAdminSelector);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    const { canEnableTenant } = useEnabledTenantOperations(tenantContextData, isAdmin, false);

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableOrchestratorDelete = useFeatureFlagValue(Features.EnableOrchestratorDelete.name);
    const EnableIntegrationsDelete = useFeatureFlagValue(Features.EnableIntegrationsDelete.name);
    const EnableTestManagerDelete = useFeatureFlagValue(Features.EnableTestManagerDelete.name);
    const EnableInsightsForAll = useFeatureFlagValue(Features.EnableInsightsForAll.name);
    const EnableAlwaysProvisionedServices = useFeatureFlagValue(Features.EnableAlwaysProvisionedServices.name);
    const EnableLogExportButtonOrchestrator = useFeatureFlagValue(Features.EnableLogExportButtonOrchestrator.name);
    const EnableLogExportButtonInsights = useFeatureFlagValue(Features.EnableLogExportButtonInsights.name);

    const { tenantId } = useTenantServicesViewModel();

    const createNotification = useUiSnackBar();

    const { data: availableServices } = useSWR(
        {
            url: tenantAvailableServicesUri,
            organizationGuid: accountId,
            accountName: logicalName,
        },
        getAvailableServices,
    );

    const {
        data: tenant, isValidating, mutate: mutateTenant,
    } = useSWR(
        {
            url: tenantByIdUri,
            id: tenantId,
        },
        getTenantById,
    );

    const { data: hasInsightsEntitlement } = useSWR(
        {
            url: entitlementsKey,
            entitlement: 'Insights.FullMode',
        },
        getEntitlements,
    );

    const serviceTypes = useMemo(() => {
        if (!tenant?.tenantServiceInstances) {
            return [];
        }

        return tenant.tenantServiceInstances
            .map(service => service.serviceType);
    }, [ tenant ]);

    const { data: tenantServiceLicenseData } = useSWR(
        tenantId && tenant
            ? {
                url: `/api/manageLicense/${accountId}/service-licenses/${tenantId}`,
                accountId,
                tenantId,
                services: serviceTypes,
            }
            : null,
        getTenantServiceLicenses,
    );

    const tenantHasServiceLicenses = useCallback((service: IService) => {
        const tenantServiceLicenses = tenantServiceLicenseData?.filter(
            tenantServiceLicense => tenantServiceLicense.serviceType === service.serviceType
            && !process.buildConfigs.unlicensedServices?.includes(tenantServiceLicense.serviceType));
        return !!tenantServiceLicenses?.some(serviceLicense => serviceLicense.products.length > 0);
    }, [ tenantServiceLicenseData ]);

    const clickedManageUsers = useCallback(
        (service: IService) => returnExternalServiceManageUsersUrl(service.serviceType, tenant?.name ?? '', logicalName),
        [ logicalName, tenant ],
    );

    const getServiceIcon = useCallback(
        (service: IService) => {
            switch (service.serviceType) {
                case 'connections' : return 'integrations';
                default: return service.serviceType;
            }
        },
        [],
    );

    const showServiceGenericError = useCallback(
        async (results: IServiceError[] | undefined, status: string) => {
            if (!Array.isArray(results)) {
                return [];
            }

            const genericFailures = results?.filter(service => service.ErrorCode !== ServiceErrorCode.InsufficientLicense);
            if (genericFailures?.length) {
                const genericFailureServiceString = genericFailures
                    .map(service => getFriendlyName(service.ServiceType))
                    .join(', ');

                await createDialog({
                    title: translate({ id: 'CLIENT_GENERIC_TENANT_FAILURE_DIALOG_HEADER' }),
                    body: translate(
                        { id: 'CLIENT_GENERIC_TENANT_FAILURE_DIALOG_BODY' },
                        {
                            status,
                            services: genericFailureServiceString,
                        },
                    ),
                    icon: 'info',
                    showCancel: true,
                });
            }

            return genericFailures;
        },
        [ createDialog, translate ],
    );

    const showServiceLicenseError = useCallback(
        async (results: IServiceError[] | undefined) => {
            if (!Array.isArray(results)) {
                return [];
            }

            const licenseFailures = results?.filter(service => service.ErrorCode === ServiceErrorCode.InsufficientLicense);
            if (licenseFailures?.length) {
                const licenseFailureServiceString = licenseFailures
                    .map(service => getFriendlyName(service.ServiceType))
                    .join(', ');

                const proceed = await createDialog({
                    title: translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE_DIALOG_HEADER' }),
                    body: translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE_DIALOG_BODY' }, { services: licenseFailureServiceString }),
                    icon: 'info',
                    primaryButtonText: translate({ id: 'CLIENT_ALLOCATE_LICENSES' }),
                    hideActions: isUnlicensed,
                    showCancel: true,
                });

                if (proceed) {
                    navigate(getRoute(getTenantConfigureServiceRoute(tenantId, 'configure')),
                        { state: { previousLocation: getRoute(RouteNames.TenantServices.replace(':tenantId', tenantId)) } });
                }
            }
            return licenseFailures;
        },
        [ createDialog, getRoute, isUnlicensed, navigate, tenantId, translate ],
    );

    const clickedRetryService = useCallback(async (service: IService) => {
        try {
            const changedServices = addHiddenDependenciesHelper([ service.serviceType ], availableServices);
            const changedServicesObj = Object.assign(
                {},
                ...changedServices.map(s => ({ [s]: true })),
            );
            mutateTenant(
                async () => {
                    await tenantOperations.tenantEdit({
                        name: tenant!.name,
                        services: tenant!.tenantServiceInstances.map(serviceIterator => serviceIterator.serviceType),
                        color: tenant!.color,
                        region: tenant!.region,
                    }, tenant!, changedServicesObj);

                    return produce(tenant, (draftState: ITenant | undefined) => {
                        const tempService = draftState?.tenantServiceInstances
                            .find(serviceIterator => serviceIterator.id === service.id);
                        if (tempService) {
                            tempService.status = TenantStatusConstants.ENABLED;
                        }
                    });
                },
                {
                    optimisticData: produce(tenant, (draftState: ITenant | undefined) => {
                        const tempService = draftState?.tenantServiceInstances
                            .find(serviceIterator => serviceIterator.id === service.id);
                        if (tempService) {
                            tempService.status = TenantStatusConstants.UPDATING;
                        }
                    }),
                },
            );

        } catch (error) {
            const errorObject = await getErrorObject(error);

            if (errorObject.response?.status === 504) {
                setErrorMessage(translate({ id: 'CLIENT_CONNECTION_TIMEOUT_MESSAGE' }));
            } else {
                const errorData = errorObject.response?.data;
                const errorResponse = typeof errorData === 'string' ? errorData : await getErrorMessage(errorObject);
                setErrorMessage(errorResponse);
            }
        }
    }, [
        availableServices,
        getErrorMessage,
        getErrorObject,
        mutateTenant,
        setErrorMessage,
        tenant,
        tenantOperations,
        translate,
    ]);

    const clickedEnableService = useCallback(
        async (service: IService) => {
            const serviceStatusMap = { [service.serviceType]: TenantStatusConstants.ENABLED };
            try {
                const parentDependencyList = getListOfDependencies(service.serviceType);
                const implicitServicesList = getListOfHiddenDependencies(service.serviceType);
                const disabledParents = parentDependencyList.filter(dep => {
                    const depService = tenant?.tenantServiceInstances.find(serviceIterator => serviceIterator.serviceType === dep);
                    return depService?.status?.toUpperCase() !== TenantStatusConstants.ENABLED;
                });
                const implicitServices = implicitServicesList.filter(dep => {
                    const depService = tenant?.tenantServiceInstances.find(serviceIterator => serviceIterator.serviceType === dep);
                    return depService?.status?.toUpperCase() !== TenantStatusConstants.ENABLED;
                });

                implicitServices.forEach(implicit => (serviceStatusMap[implicit] = TenantStatusConstants.ENABLED));
                if (disabledParents.length > 0) {
                    const parentString = disabledParents.map(parent => getFriendlyName(parent)).join(', ');
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_ENABLE_SERVICE' }, { service: getFriendlyName(service.serviceType) }),
                        body: (
                            <>
                                <UiText>
                                    {translate({ id: 'CLIENT_ENABLE_SERVICE_BODY1' }, { parents: parentString })}
                                </UiText>
                                <UiText style={{ marginTop: '12px' }}>
                                    {translate({ id: 'CLIENT_ENABLE_SERVICE_BODY2' }, { parents: parentString })}
                                </UiText>
                            </>
                        ),
                        icon: 'info',
                        primaryButtonText: translate({ id: 'CLIENT_CONTINUE' }),
                        showCancel: true,
                    });

                    if (!proceed) {
                        return;
                    }

                    disabledParents.forEach(parent => (serviceStatusMap[parent] = TenantStatusConstants.ENABLED));
                }

                await mutateTenant(
                    async () => {
                        await changeServiceStatus(tenant!, serviceStatusMap);
                        return produce(tenant, (draftState: ITenant | undefined) => {
                            const tempService = draftState?.tenantServiceInstances
                                .find(serviceIterator => serviceIterator.id === service.id);
                            if (tempService) {
                                tempService.status = TenantStatusConstants.ENABLED;
                            }
                        });
                    },
                    {
                        optimisticData: produce(tenant, (draftState: ITenant | undefined) => {
                            draftState!.status = TenantStatusConstants.UPDATING;
                            const tempService = draftState?.tenantServiceInstances
                                .find(serviceIterator => serviceIterator.id === service.id);
                            if (tempService) {
                                tempService.status = TenantStatusConstants.ENABLE_STATUS_INPROGRESS;
                            }
                        }),
                    },
                );

                refreshAfterComplete(tenantId);

                createNotification(
                    translate(
                        { id: 'CLIENT_ENABLE_SERVICE_NOTIFICATION_REVAMP' },
                        {
                            service: getFriendlyName(service.serviceType),
                            tenantName: tenant?.name,
                        },
                    ),
                    notificationType.SUCCESS,
                );

                if (tenantHasServiceLicenses(service)) {
                    navigate(
                        getTenantConfigureServiceRoute(tenantId, 'enable', service.serviceType),
                        { state: { services: Object.keys(serviceStatusMap) } },
                    );
                }
            } catch (error) {
                const results: IServiceError[] = error as any;
                const genericFailures = await showServiceGenericError(results, TenantStatusConstants.ENABLE);
                if (genericFailures?.length) {
                    return;
                }

                const licenseFailures = await showServiceLicenseError(results);
                if (licenseFailures?.length) {
                    return;
                }

                setErrorMessage(error);
            }
        },
        [
            mutateTenant,
            tenant,
            createNotification,
            translate,
            tenantHasServiceLicenses,
            createDialog,
            changeServiceStatus,
            navigate,
            tenantId,
            showServiceGenericError,
            showServiceLicenseError,
            setErrorMessage,
            refreshAfterComplete,
        ],
    );

    const clickedDisableService = useCallback(
        async (service: IService) => {
            try {
                const childDependencyList = getListOfParents(service.serviceType);
                const enabledChildren = childDependencyList.filter(dep => {
                    const depService = tenant!.tenantServiceInstances.find(serviceIterator => serviceIterator.serviceType === dep);
                    return depService?.status?.toUpperCase() === TenantStatusConstants.ENABLED;
                });
                const serviceStatusMap = { [service.serviceType]: TenantStatusConstants.DISABLED };
                const childrenString = enabledChildren
                    ?.filter(child => !servicesToHide.includes(child))
                    .map(child => getFriendlyName(child))
                    .join(', ');
                const proceed = await createDialog({
                    title: translate(
                        { id: 'CLIENT_DISABLE_SERVICE' },
                        { service: getFriendlyName(service.serviceType) },
                    ),
                    body: (
                        <>
                            <div className={classes.spacerText}>
                                <FormattedMessage
                                    id="CLIENT_DISABLE_SERVICE_BODY_REVAMP"
                                    values={{
                                        b: (chunk: React.ReactNode[]) => <b>
                                            {chunk}
                                        </b>,
                                        service: getFriendlyName(service.serviceType),
                                    }}
                                />
                            </div>
                            {childrenString && <div className={clsx(classes.spacerText, classes.dependencyText)}>
                                <FormattedMessage
                                    id="CLIENT_DISABLE_SERVICE_BODY_REVAMP_DEPENDENCIES"
                                    values={{
                                        b: (chunk: React.ReactNode[]) => <b>
                                            {chunk}
                                        </b>,
                                        parent: getFriendlyName(service.serviceType),
                                        children: childrenString,
                                    }}
                                />
                            </div>}
                            <FormattedMessage
                                id="CLIENT_DISABLE_SERVICE_BODY_REVAMP_CONFIRM"
                                values={{
                                    b: (chunk: React.ReactNode[]) => <b>
                                        {chunk}
                                    </b>,
                                    service: getFriendlyName(service.serviceType),
                                }}
                            />
                        </>
                    ),
                    icon: 'warning',
                    primaryButtonText: translate({ id: 'CLIENT_DISABLE' }),
                    showCancel: true,
                });

                if (!proceed) {
                    return;
                }

                await mutateTenant(
                    async () => {
                        enabledChildren.forEach(child => (serviceStatusMap[child] = TenantStatusConstants.DISABLED));
                        await changeServiceStatus(tenant!, serviceStatusMap);
                        return produce(tenant, (draftState: ITenant) => {
                            const tempService = draftState.tenantServiceInstances
                                .find(serviceIterator => serviceIterator.id === service.id);
                            if (tempService) {
                                tempService.status = TenantStatusConstants.DISABLED;
                            }
                        });
                    },
                    {
                        optimisticData: produce(tenant, (draftState: ITenant) => {
                            draftState.status = TenantStatusConstants.UPDATING;
                            const tempService = draftState.tenantServiceInstances
                                .find(serviceIterator => serviceIterator.id === service.id);
                            if (tempService) {
                                tempService.status = TenantStatusConstants.DISABLE_STATUS_INPROGRESS;
                            }
                        }),
                    },
                );

                refreshAfterComplete(tenantId);

                createNotification(
                    translate(
                        { id: 'CLIENT_DISABLE_SERVICE_NOTIFICATION' },
                        { service: getFriendlyName(service.serviceType) },
                    ),
                    notificationType.SUCCESS,
                );
            } catch (error) {
                const results: IServiceError[] = error as any;
                const genericFailures = await showServiceGenericError(results, TenantStatusConstants.ENABLE);
                if (genericFailures?.length) {
                    return;
                }

                setErrorMessage(error);
            }
        },
        [
            createDialog,
            translate,
            classes.spacerText,
            classes.dependencyText,
            mutateTenant,
            tenant,
            createNotification,
            servicesToHide,
            changeServiceStatus,
            showServiceGenericError,
            setErrorMessage,
            refreshAfterComplete,
            tenantId,
        ],
    );

    const clickedDeleteService = useCallback(
        async (service: IService) => {
            try {
                const proceed = await createDialog({
                    title: translate({ id: 'CLIENT_REMOVE_SERVICE' }, { 0: getFriendlyName(service.serviceType) }),
                    customDialogContent: ServiceDeleteDialogBody,
                    customDialogContentProps: {
                        tenant: tenant!,
                        service: service.serviceType,
                        tenantEdit: tenantOperations.tenantEdit,
                    },
                    icon: 'error',
                });

                if (!proceed) {
                    return;
                }

                await mutateTenant(
                    async () => produce(tenant, (draftState: ITenant) => {
                        draftState.status = TenantStatusConstants.UPDATING;
                        draftState.tenantServiceInstances =
                                draftState.tenantServiceInstances.filter(serviceIterator => serviceIterator.id !== service.id);
                    }),
                    { revalidate: false },
                );

                refreshAfterComplete(tenantId);
            } catch (error) {
                const results: IServiceError[] = error as any;
                const genericFailures = await showServiceGenericError(results, TenantStatusConstants.ENABLE);
                if (genericFailures?.length) {
                    return;
                }

                setErrorMessage(error);
            }
        },
        [
            createDialog,
            translate,
            tenant,
            tenantOperations.tenantEdit,
            showServiceGenericError,
            setErrorMessage,
            mutateTenant,
            refreshAfterComplete,
            tenantId,
        ],
    );

    const clickedConfigureLicense = useCallback(
        (service: IService) => {
            navigate(getRoute(getTenantConfigureServiceRoute(tenantId, 'configure', service.serviceType)),
                { state: { previousLocation: getRoute(RouteNames.TenantServices.replace(':tenantId', tenantId)) } });
        },
        [ getRoute, navigate, tenantId ],
    );

    const clickedConfigureRobotLogs = useCallback(
        (service: IService) => {
            if (service.serviceType === 'insights') {
                logEvent(AdminInsightsExportEvent.Click);
            }

            if (tenant?.name) {
                navigate(
                    {
                        pathname: RouteNames.TenantServiceLogExport,
                        search: createSearchParams({
                            tenantName: tenant.name,
                            serviceType: service.serviceType,
                        }).toString(),
                    }
                );
            }
        },
        [ tenant, logEvent, navigate ],
    );

    const navigateToService = useCallback(async (service: IService) => {
        try {
            logEvent(ServiceEvent.Open, {
                CloudTenantId: tenantId,
                ServiceName: service.serviceType,
            });
            window.location.assign(urljoin(window.location.origin, logicalName, tenant!.name, service.serviceType + '_'));
        } catch (e) {
            portalTelemetry.trackTrace({
                message: `Error tracking Service.Open event ${service.serviceType}`,
                severityLevel: SeverityLevel.Major,
            });
        }
    }, [ logEvent, logicalName, tenant, tenantId ]);

    // TODO: Cleanup feature flags and logic
    const isOrchestratorActionVisible = useCallback((service: IService) => (service.serviceType === 'orchestrator' && !EnableOrchestratorDelete), [ EnableOrchestratorDelete ]);
    const isConnectionsActionVisible = useCallback((service: IService) => (service.serviceType === 'connections' && !EnableIntegrationsDelete), [ EnableIntegrationsDelete ]);
    const isInsightsActionVisible = useCallback((service: IService) => (service.serviceType === 'insights' && EnableInsightsForAll), [ EnableInsightsForAll ]);
    const isTestManagerActionVisible = useCallback((service: IService) => (service.serviceType === 'testmanager' && !EnableTestManagerDelete), [ EnableTestManagerDelete ]);

    const isServiceAlwaysProvisioned = useCallback((service: IService) =>
        EnableAlwaysProvisionedServices
            && !!(availableServices?.some(availableService => availableService.id === service.serviceType && availableService.isAlwaysProvision))
            && isInsightsActionVisible(service),
    [ EnableAlwaysProvisionedServices, availableServices, isInsightsActionVisible ]);

    const moreActions: ICardAction[] = useMemo(() => [
        {
            label: translate({ id: 'CLIENT_LAUNCH' }),
            click: navigateToService,
            dataCy: 'launch-service',
            ariaLabel: translate({ id: 'CLIENT_LAUNCH' }),
            invisible: (service: IService) => service.status.toUpperCase() !== TenantStatusConstants.ENABLED,
        },
        {
            label: translate({ id: 'CLIENT_ENABLE' }),
            click: clickedEnableService,
            dataCy: 'enable-service',
            ariaLabel: translate({ id: 'CLIENT_ENABLE' }),
            disable: tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: (service: IService) => service.status.toUpperCase() !== TenantStatusConstants.DISABLED,
        },
        {
            label: translate({ id: 'CLIENT_DISABLE' }),
            click: clickedDisableService,
            dataCy: 'disable-service',
            ariaLabel: translate({ id: 'CLIENT_DISABLE' }),
            disable: tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: (service: IService) => ((service.status.toUpperCase() !== TenantStatusConstants.ENABLED
                || isConnectionsActionVisible(service)
                || isInsightsActionVisible(service)
                || isServiceAlwaysProvisioned(service))
            ),
        },
        {
            label: (service: IService) => translate({
                id: service.serviceType === 'orchestrator'
                    ? 'CLIENT_LOG_EXPORT_CONFIGURATION'
                    : 'CLIENT_INSIGHTS_EXPORT_DATA',
            }),
            click: clickedConfigureRobotLogs,
            dataCy: 'export-log',
            ariaLabel: (service: IService) => translate({
                id: service.serviceType === 'orchestrator'
                    ? 'CLIENT_LOG_EXPORT_CONFIGURATION'
                    : 'CLIENT_INSIGHTS_EXPORT_DATA',
            }),
            disable: tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: (service: IService) => {
                const services = [];
                if (EnableLogExportButtonOrchestrator) {
                    // Enable only for Cloud or Dedicated
                    services.push('orchestrator');
                }
                if (EnableLogExportButtonInsights && (!EnableInsightsForAll || hasInsightsEntitlement)) {
                    services.push('insights');
                }
                return !services.includes(service.serviceType) ||
                !isAdmin ||
                isUnlicensedMode ||
                AccountLicense[subscriptionType] >= AccountLicense.COMMUNITY;
            },
        },
        {
            label: translate({ id: 'CLIENT_API_ACCESS' }),
            click: () => setCurrentTenant(tenant!.name),
            dataCy: 'api-access',
            ariaLabel: translate({ id: 'CLIENT_API_ACCESS' }),
            disable: tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: (service: IService) => service.serviceType !== 'orchestrator' || !(checkTokenTypeIsAuth0 && !DisableFeatureFedRamp),
        },
        {
            label: translate({ id: 'CLIENT_REMOVE' }),
            click: clickedDeleteService,
            dataCy: 'delete-service',
            ariaLabel: translate({ id: 'CLIENT_REMOVE' }),
            disable: tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: (service: IService) => (
                (isOrchestratorActionVisible(service)
                    || isConnectionsActionVisible(service)
                    || isTestManagerActionVisible(service)
                    || isInsightsActionVisible(service)
                    || isServiceAlwaysProvisioned(service))
            ),
        },
    ], [
        translate,
        navigateToService,
        clickedEnableService,
        tenant,
        clickedDisableService,
        clickedConfigureRobotLogs,
        clickedDeleteService,
        isConnectionsActionVisible,
        isInsightsActionVisible,
        isServiceAlwaysProvisioned,
        EnableLogExportButtonOrchestrator,
        EnableLogExportButtonInsights,
        EnableInsightsForAll,
        hasInsightsEntitlement,
        isAdmin,
        isUnlicensedMode,
        subscriptionType,
        setCurrentTenant,
        checkTokenTypeIsAuth0,
        DisableFeatureFedRamp,
        isOrchestratorActionVisible,
        isTestManagerActionVisible,
    ]);

    const services = useMemo(() =>
        listOfServices(tenant?.tenantServiceInstances, servicesToHide)
    , [ servicesToHide, tenant?.tenantServiceInstances ]);

    const checkedServices = useMemo(
        () => services?.map(service => service.serviceType),
        [ services ],
    );

    const editAvailableServices = useMemo(() => availableServices?.filter(i => !checkedServices?.includes(i.id) && i.isVisible),
        [ availableServices, checkedServices ]);

    const hiddenServices = useMemo(() => services ? getHiddenServices(services, availableServices) : null, [ availableServices, services ]);

    const displayedServices = useMemo(() => {
        const data = editAvailableServices;
        return data?.filter(service => servicesToHide.indexOf(service.id) < 0 && hiddenServices && hiddenServices.indexOf(service.id) < 0);
    }, [ editAvailableServices, hiddenServices, servicesToHide ]);

    const { breadcrumbs } = useBreadcrumbs();

    const cardActions = useCallback((service: IService) => (
        <>
            {service.status.toUpperCase() !== TenantStatusConstants.FAILED && <>
                {tenantHasServiceLicenses(service) && <Tooltip title={translate({ id: 'CLIENT_CONFIGURE_LICENSE_ALLOCATION' })}>
                    <IconButton
                        style={{ marginRight: '-4px' }}
                        disabled={!isOrgAdmin
                                || service.status.toUpperCase() !== TenantStatusConstants.ENABLED
                                || service.status === TenantStatusConstants.ENABLE_STATUS_INPROGRESS
                                || service.status === TenantStatusConstants.DISABLE_STATUS_INPROGRESS}
                        onClick={() => clickedConfigureLicense(service)}
                        data-cy="service-card-license-allocation">
                        <AllocateLicenseIcon
                            className={{
                                [classes.licenseDisabled]: !isOrgAdmin
                                || service.status.toUpperCase() !== TenantStatusConstants.ENABLED
                                || service.status === TenantStatusConstants.ENABLE_STATUS_INPROGRESS
                                || service.status === TenantStatusConstants.DISABLE_STATUS_INPROGRESS,
                            }}
                        />
                    </IconButton>
                </Tooltip>}
                {isUserManagementEnabled(service.serviceType) &&
                    <Tooltip title={translate({ id: 'CLIENT_MANAGE_USERS' })}>
                        <IconButton
                            href={clickedManageUsers(service)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginRight: '-4px' }}
                            disabled={service.status.toUpperCase() !== TenantStatusConstants.ENABLED
                                || service.status === TenantStatusConstants.ENABLE_STATUS_INPROGRESS
                                || service.status === TenantStatusConstants.DISABLE_STATUS_INPROGRESS}
                            data-cy="service-card-manage-users">
                            <PeopleAltOutlinedIcon />
                        </IconButton>
                    </Tooltip>}
            </>}
            {moreActions.every((action) =>
                action.invisible !== undefined
                            && (typeof action.invisible === 'function' ?
                                action.invisible(service)
                                : action.invisible
                            )) ? null :
                <UiMoreActionsComponent
                    service={service}
                    actions={moreActions}
                    disabled={service.status === TenantStatusConstants.ENABLE_STATUS_INPROGRESS
                            || service.status === TenantStatusConstants.DISABLE_STATUS_INPROGRESS}
                />}
        </>
    ), [
        classes.licenseDisabled,
        clickedConfigureLicense,
        clickedManageUsers,
        isOrgAdmin,
        moreActions,
        tenantHasServiceLicenses,
        translate,
    ]);

    const tenantServiceButtons = useMemo(() => (
        <UiStack
            direction="row"
            gap={SpacingToken.XS}
            align="center">
            <UiRefreshButton
                loading={isValidating}
                onClick={() => mutateTenant()} />
            {displayedServices && displayedServices.length > 0 &&
            <ApButton
                size='small'
                variant='primary'
                disabled={tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED}
                onClick={() => {
                    navigate(RouteNames.TenantServicesAdd);
                }}
                style={{ width: 'max-content' }}
                data-cy='tenant-add-services-button'
                label={translate({ id: 'CLIENT_ADD_SERVICES' })}>
                <PortalIcon
                    slot="start-icon"
                    name="add" />
            </ApButton>}
        </UiStack>
    ), [ displayedServices, isValidating, navigate, tenant?.status, translate, mutateTenant ]);

    return (
        <>
            <ApiAccessInstanceModalComponent />
            <UiPageContainer
                breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
                banner={<UiTenantStatusBanner
                    canEnableTenant={canEnableTenant}
                    status={tenant?.status}
                    tenantName={tenant?.name} />}
                primaryActions={tenantServiceButtons}
                loading={!services || !availableServices}>
                <UiCardGrid
                    maxCardWidth='320px'
                    gap='16px'>
                    {services?.map((service, i) =>
                        <UiServiceCard
                            key={i}
                            style={{
                                width: '320px',
                                height: '188px',
                            }}
                            title={getFriendlyName(service.serviceType)}
                            titleIcon={
                                <PortalCustomIcon
                                    name={getServiceIcon(service)}
                                    color={service.status.toUpperCase() === TenantStatusConstants.DISABLED
                                        ? theme.palette.semantic.colorForegroundDisable
                                        : theme.palette.semantic.colorInfoForeground}
                                />
                            }
                            dataCy={`${service.serviceType}-service-card`}
                            status={service.status.toUpperCase()}
                            description={translate({ id: `CLIENT_${service.serviceType.toUpperCase()}_DESCRIPTION` })}
                            actions={cardActions(service)}
                            retry={<Button
                                style={{ marginLeft: '4px' }}
                                variant="text"
                                startIcon={<RefreshIcon />}
                                onClick={() => clickedRetryService(service)}
                                data-cy="retry-service"
                                aria-label={translate({ id: 'CLIENT_TRY_AGAIN' })}
                                disabled={tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED}>
                                {translate({ id: 'CLIENT_TRY_AGAIN' })}
                            </Button>}
                            link={urljoin(window.location.origin, logicalName, tenant!.name, service.serviceType + '_')} />,
                    )}
                </UiCardGrid>
            </UiPageContainer>
        </>
    );
};

export const TenantServicesComponentWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const { tenantId } = useParams<{ tenantId: string }>();

    const { data: tenant } = useSWR(
        tenantId ? {
            url: tenantByIdUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: RouteNames.TenantHome.replace(':tenantId', tenantId ?? ''),
            name: tenant?.name ?? translate({ id: 'CLIENT_TENANT' }),
        },
        {
            index: 1,
            link: RouteNames.TenantServices.replace(':tenantId', tenantId ?? ''),
            name: translate({ id: 'CLIENT_TENANTS' }),
        },
    ], [ tenant, tenantId, translate ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <TenantServicesComponent />
        <UiSuspensefulOutlet />
    </BreadcrumbProvider>;
};

export default TenantServicesComponent;
