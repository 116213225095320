import type { ITenant } from '@experiences/interfaces';

import {
    USER_TYPE_ICON,
    type UserType,
} from '../../../common/constants/UsersConstant';
import {
    getGroupsAllocationsPage,
    getLicenseInheritance,
    getUsersExplicitAllocationsPage,
} from '../../../services/licensing/accountant/UserLicenseService';
import type { ProfileLicense } from './ProfileConstants';

export function getSuggestValue<T = UserType | string>(value: T[]) {
    return value?.map(s => ({
        id: s,
        text: s,
    }));
}

// TODO: add filters
// export function getSearchSourceFactory<T = UserType | string>(search?: string, inputs: Array<{ name: string; value: T }> = []) {
//     return Promise.resolve({
//         data: inputs.filter(input => search ? `${input.value}`.includes(search) : true).map((i) => ({
//             id: i.value,
//             text: i.name,
//             value: i.value,
//         })),
//         total: inputs.filter(input => search ? `${input.value}`.includes(search) : true).length,
//     });
// }

export function findTenantByTenantOrServiceId(tenants: ITenant[], searchId: string) {
    return tenants.find(tenant => tenant.id === searchId || tenant.tenantServiceInstances.some(service => service.id === searchId));
}

export const getEntityLicenseAllocations = async (isGroup: boolean, id: string, enableExternalUserLicense: boolean): Promise<ProfileLicense[]> => {
    if (isGroup) {
        const groupData = await getGroupsAllocationsPage({ selectedGroupId: id });
        return groupData.results[0].userBundleLicenses.map((userBundleLicense) => ({ id: userBundleLicense }));
    }

    const [
        userData,
        licenseInheritance,
    ] = await Promise.all([
        getUsersExplicitAllocationsPage({ selectedUserId: id }),
        getLicenseInheritance({ userId: id }),
    ]);

    const userResult = userData.results[0].userBundleLicenses
        .concat(enableExternalUserLicense ? [ 'EXTERNAL_LICENSE' ] : [])
        .map((license) => ({ id: license }));

    const groupInheritedLicenses = licenseInheritance.currentlyUsedLicense.flatMap((group) => group.userBundleCodes.map((license) => ({
        id: license,
        groupId: group.groupId,
        groupName: group.group,
    })));

    return userResult.concat(groupInheritedLicenses);
};

export const getUserTypeIconTranslationKey = (userType?: UserType) => {
    const userTypeIcon = USER_TYPE_ICON[userType ?? 0];

    // This is a fallback for when the userType is not found in the USER_TYPE_ICON constant.
    if (!userTypeIcon) {
        return '';
    }

    return `CLIENT_${userTypeIcon.toUpperCase()}`;
};
