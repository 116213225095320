import { useEcommerceTelemetry } from '@experiences/ecommerce';
import { useGetErrorInfo } from '@experiences/error';
import { useLocalization } from '@experiences/locales';
import { UiText } from '@experiences/ui-common';
import {
    formatDate,
    useModalState,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import useSWR, { useSWRConfig } from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import {
    applyMonthlyUpsell,
    billingUrl,
    fetchPlanDetails,
    validateMonthlyUpsellStartDate,
} from '../../services/licensing/BillingService';
import { licenseManagementAccountUrl } from '../../services/licensing/management/AccountService';
import { trialServicesSwrKey } from '../../services/licensing/TrialPerSku';
import { getUserOrganizationInfo } from '../../services/organization/UserOrganizationInfoService.default';
import { setUserProfile } from '../../store/action/UserProfileAction';
import validateLicenseCode from '../../util/validators/LicenseCodeValidator';
import type { IActivationData } from '../licensing/interfaces/license';
import LicenseActivationErrorDialogBody from '../licensing/subcomponents/LicenseActivationErrorDialogBody';

const useStyles = makeStyles(_ =>
    createStyles({ confirmActivationDialogContent: { width: '416px' } }),
);

// Ignore cognitive complexity rule for ViewModel since it's more like a constructor than a function
// eslint-disable-next-line
const useEcommerceMonthlyUpsellViewModel = (
    accountName: string,
) => {
    const { formatMessage: translate } = useIntl();
    const { getErrorObject } = useGetErrorInfo();
    const createNotification = useUiSnackBar();

    const getRoute = useRouteResolver();
    const classes = useStyles();
    const createDialog = useShowDialog();
    const logEcommerceEvent = useEcommerceTelemetry();
    const language = useLocalization();

    const [ loading, setLoading ] = useState(false);
    const [ hasActivationValidationError, setHasActivationValidationError ] = useState(false);
    const [ licenseStartDate, setLicenseStartDate ] = useState<string>();
    const [ hasFormErrors, setHasFormErrors ] = useState(false);
    const [ formErrorMessage, setFormErrorMessage ] = useState<string>();

    const { mutate } = useSWRConfig();

    const {
        data: planDetails, isValidating: isPlanDetailsLoading,
    } = useSWR(
        {
            url: `${billingUrl}/planDetails`,
            accountName,
        },
        fetchPlanDetails,
    );

    const {
        control, handleSubmit, watch,
    } = useForm<IActivationData>({
        mode: 'onSubmit',
        defaultValues: { licenseCode: '' },
        shouldUnregister: false,
    });

    const {
        open, close,
    } = useModalState(getRoute(RouteNames.Licensing));

    const formLicenseCode = watch('licenseCode');

    const onSubmit = useCallback(
        async (data: IActivationData) => {
            setLoading(true);
            setHasFormErrors(false);
            setFormErrorMessage(undefined);
            setHasActivationValidationError(false);
            if (!validateLicenseCode(data.licenseCode)) {
                setHasFormErrors(true);
                setFormErrorMessage(translate({ id: 'CLIENT_ACTIVATION_INVALID_LICENSE_CODE' }));
                setLoading(false);
                return;
            }

            try {
                const validationResponse = await validateMonthlyUpsellStartDate(accountName, data.licenseCode);
                if (!validationResponse.isLicenseActivationValid) {
                    if (!isPlanDetailsLoading && !planDetails?.upcomingBill) {
                        await createDialog({
                            title: translate({ id: 'CLIENT_ACTIVATE_FAILED' }),
                            icon: 'error',
                            unclosable: false,
                            showCancel: false,
                            customDialogContent: LicenseActivationErrorDialogBody,
                            customDialogContentProps: { operationStatus: '-101' },
                        });
                    } else {
                        setHasActivationValidationError(true);
                        setLicenseStartDate(validationResponse.licenseStartDate);
                        setHasFormErrors(true);
                        setFormErrorMessage(translate({ id: 'CLIENT_ECOMMERCE_MONTHLY_UPSELL_ACTIVATION_BEFORE_LICENSE_START_DATE' },
                            { licenseStartDate: formatDate(validationResponse.licenseStartDate, language) }));
                    }
                    logEcommerceEvent('Licenses.MonthlyUpsellStartDateValidationError');
                    setLoading(false);
                    return;
                }
            } catch (error) {
                const errorObject = await getErrorObject(error);
                const operationStatus = errorObject.response?.status === 404 ? '-103' : errorObject.response.data.ErrorCode;
                logEcommerceEvent('Licenses.MonthlyUpsellError', { LicenseActivationOperationStatus: operationStatus });
                await createDialog({
                    title: translate({ id: 'CLIENT_ACTIVATE_FAILED' }),
                    icon: 'error',
                    unclosable: false,
                    showCancel: false,
                    customDialogContent: LicenseActivationErrorDialogBody,
                    customDialogContentProps: { operationStatus },
                });
                setLoading(false);
                return;
            }

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_ECOMMERCE_MONTHLY_UPSELL_CONFIRM_ACTIVATION_DIALOG_TITLE' }),
                body: (<UiText className={classes.confirmActivationDialogContent}>
                    <FormattedMessage
                        id="CLIENT_ECOMMERCE_MONTHLY_UPSELL_CONFIRM_ACTIVATION_DIALOG_BODY"
                        values={{
                            // eslint-disable-next-line react/no-unstable-nested-components
                            b: (msg: React.ReactNode[]) => <b>
                                {msg}
                            </b>,
                        }}
                    />
                </UiText>),
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
                dataCy: 'monthly-upsell-confirmation-dialog',
            });

            if (proceed) {
                try {
                    logEcommerceEvent('Licenses.MonthlyUpsell');

                    setLoading(true);
                    await applyMonthlyUpsell(accountName, { licenseCode: data.licenseCode });

                    close(true);
                    createNotification(
                        translate({ id: 'CLIENT_ECOMMERCE_MONTHLY_UPSELL_SUCCESS_NOTIFICATION' }),
                        notificationType.SUCCESS
                    );

                    const userOrganizationInfo = await getUserOrganizationInfo();
                    if (userOrganizationInfo) {
                        await setUserProfile(userOrganizationInfo);
                    }

                    await mutate(licenseManagementAccountUrl);
                    await mutate(`${licenseManagementAccountUrl}/available`);
                    await mutate(trialServicesSwrKey);
                } catch (error) {
                    const errorObject = await getErrorObject(error);
                    const operationStatus = errorObject.response?.status === 400 ? errorObject.response.data.ErrorCode : '-1510';
                    logEcommerceEvent('Licenses.MonthlyUpsellError', { LicenseActivationOperationStatus: operationStatus });
                    await createDialog({
                        title: translate({ id: 'CLIENT_ACTIVATE_FAILED' }),
                        icon: 'error',
                        unclosable: false,
                        showCancel: false,
                        customDialogContent: LicenseActivationErrorDialogBody,
                        customDialogContentProps: { operationStatus },
                    });
                }
            }
            setLoading(false);
        },
        [
            createDialog,
            translate,
            classes.confirmActivationDialogContent,
            accountName,
            isPlanDetailsLoading,
            planDetails?.upcomingBill,
            logEcommerceEvent,
            language,
            getErrorObject,
            close,
            createNotification,
            mutate,
        ],
    );

    return {
        licenseStartDate,
        hasActivationValidationError,
        shouldActivationButtonBeLoading: loading,
        shouldActivationButtonBeDisabled: loading || !formLicenseCode,
        methods: {
            control,
            handleSubmit: handleSubmit(onSubmit),
        },
        hasFormErrors,
        formErrorMessage,
        closeDrawer: close,
        openDrawer: open,
        onSubmit,
    };
};

export default useEcommerceMonthlyUpsellViewModel;
